.users {
  .users-wrap {
    display: flex;
    align-items: flex-start;
  }
  .users-left {
    flex-grow: 1;
    max-width: 470px;
  }
  .users-new__items > div {
    margin-bottom: 32px;
  }
  .users-new__items > div:last-child {
    margin-bottom: 0;
  }

  .users-items {
    padding: 25px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .users-radio > span {
    width: auto;
    max-width: 48px;
    width: 100%;
  }
  .users-radio > div {
    width: 306px;
  }
  .users-new {
    background-color: #f8f9fb;
    margin-left: 80px;
    flex-grow: 1;
    max-width: 584px;
    padding: 41px 39px 38px;
  }
  .users-new__title {
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .users-btn {
    display: flex;
    margin-top: 49px;
  }
  .users-btn > button {
    &:first-child {
      margin-right: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .users-search {
    max-width: 471px;
  }
  .users-search > div {
    margin-bottom: 29px;
  }
  .users-search__items {
    overflow: auto;
    min-height: 200px;
    &.users-search__offices {
      max-height: 605px;
    }
    &.users-search__users {
      max-height: 733px;
    }
  }
  .users-search__item {
    background: #f8f9fb;
    border-radius: 8px;
    padding: 21px 20px 15px;
    margin-bottom: 29px;
  }
  .users-search > div:last-child,
  .users-search__item:last-child {
    margin-bottom: 0;
  }
  .users-searchItem__wrap {
    display: flex;
    margin-bottom: 15px;
  }
  .statue {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e0e0e0;
    margin-top: 3px;
    margin-right: 8px;
  }
  .statue.active {
    background: #09af00;
  }
  .users-searchItem__role {
    margin-left: 15px;
    margin-top: 3px;
  }
  .users-searchItem__wrap > button {
    display: flex;
    margin-top: 1px;
    margin-left: auto;
  }
  .users-searchItem__email,
  .users-searchItem__phone {
    font-family: 'Mont';
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #809fb8;
    margin-left: 16px;
    margin-top: 5px;
    word-break: break-word;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .tabs {
      max-width: 92%;
    }
    .users-left {
      max-width: 511px;
    }
    .users-search {
      max-width: 100%;
      margin-right: 0px;
    }
    .users-search__item {
      padding: 21px 20px 20px;
    }
    .users-new {
      max-width: 449px;
      margin-left: 40px;
      padding: 31px 30px 29px;
    }
    .users-new__title {
      margin-bottom: 19px;
    }
    .users-radio {
      margin-top: 42px;
    }
    .users-btn {
      margin-top: 35px;
    }
    .users-radio > span {
      margin-right: 21px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .users-new {
      display: none;
    }
    .users-left {
      max-width: 480px;
    }
    .users-search {
      max-width: 100%;
    }
    div.add-user__btn {
      display: flex !important;
      align-items: center;
    }
    .add-user__btn span {
      margin-left: 7px;
      font-size: 14px;
    }
    &.content {
      padding: 40px 50px 26px 50px;
    }
    .projects-naw span {
      font-size: 18px !important;
    }
    .content__title {
      margin-bottom: 32px;
    }
    .tabs {
      justify-content: initial;
      margin-bottom: 30px;
    }
    .tabs button:first-child {
      margin-right: 60px;
    }
    .tabs button {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .users-search__item {
      margin-bottom: 19px;
    }
    .add-user__btn span {
      margin-left: 0;
      margin-right: 7px;
    }
  }

  @media screen and (max-width: 1023px) {
    div.content__title {
      margin-bottom: 23px;
    }
    .tabs {
      justify-content: initial;
      margin-bottom: 31px;
    }
    .tabs button {
      font-size: 18px;
      padding-bottom: 7px;
    }
    .tabs button:first-child {
      margin-right: 60px;
    }
    .users-left {
      max-width: 100%;
    }
    .users-search {
      max-width: 100%;
    }
    .users-search .input-icon__btn svg {
      width: 20px;
      height: 18px;
    }
    .add-user__btn span {
      font-size: 14px;
      display: block;
      margin-right: 10px;
    }
    div.add-user__btn {
      display: flex !important;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 14px;
    }
    .users-search__item {
      margin-bottom: 21px;
    }
    .users-new {
      display: none;
    }
  }
}
