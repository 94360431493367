body div.UICalendar-wrap {
  width: 347px;
  background: #f8f9fb;
  border-radius: 8px;
  padding: 30px 23.3px;
  .fc.fc-theme-standard .fc-scrollgrid,
  .fc.fc-theme-standard td,
  .fc.fc-theme-standard th {
    border: none;
    border-top-style: none;
  }
  .dataActive {
    background: #3a36db;
    color: #fff;
    font-family: 'Mont';
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
  }
  .dayCellContent {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding-top: 10%;
    &.disabled {
      opacity: 0.4;
    }
  }
  .fc {
    .fc-toolbar.fc-header-toolbar {
      padding-bottom: 27px;
      border-bottom: 1px solid #e0e0e0;
      .fc-toolbar-title {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        text-transform: capitalize;
      }
      .fc-button-primary {
        background: transparent;
        padding: 0;
        border: none;
        color: #809fb8;
      }
    }
    .fc-daygrid-day-number {
      padding: 0;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      display: none;
    }
    .fc-daygrid-day-top {
      justify-content: center;
      height: 43.8px;
      align-items: center;
    }
    .fc-daygrid-day.fc-day-today {
      background-color: transparent;
      .fc-daygrid-day-number {
        background: #809fb8;
        color: #fff;
        font-family: 'Mont';
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
      }
    }
    .fc-col-header-cell-cushion {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      text-transform: uppercase;
      color: #b3b3b3;
    }
    .fc-cell-shaded,
    .fc-day-disabled,
    .fc-scrollgrid-section-sticky > * {
      background: transparent;
    }
    .fc-col-header,
    .fc-scrollgrid-section-sticky {
      margin-bottom: 10px;
    }
  }
}
