.project_addEvent {
  .projects {
    display: flex;
    .projects-left {
      flex-grow: 1;
      max-width: 804px;
    }
    .projects-left__title {
      color: #000000;
      padding-bottom: 32px;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 30px;
    }
    .projects-left__title span {
      color: #868686;
      margin-left: 12px;
    }
    .projects-items {
      max-height: 600px;
      overflow-y: auto;
    }
    .projects-item {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 29px;
    }
    .projects-item:last-child {
      margin-bottom: 0;
    }
    .projects-item__top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 14px;
      margin-bottom: 15px;
    }
    .projects-item__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > a {
        display: flex;
        width: 100%;
        align-items: center;
        > span:first-child {
          flex-grow: 1;
        }
      }
    }
    .projects-item__bottom > button {
      line-height: 28px;
      color: #000000;
      font-weight: 600;
      transition: all 0.4s;
      cursor: pointer;
      word-break: break-all;
    }
    .projects-item__bottom > button:hover {
      color: #3a36db;
    }
    .projects-item__bottom > button {
      color: #868686;
    }

    .projects-right {
      flex-grow: 1;
      margin-left: 80px;
      background: #f8f9fb;
      border-radius: 8px;
      max-width: 347px;
      max-height: 447px;
      display: flex;
      flex-direction: column;
    }
    .projects-right .btn {
      margin-top: auto;
      margin-top: 20px;
      width: 100%;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1500px) {
      .projects-left__title span {
        font-size: 18px !important;
      }
      .projects-left__title {
        padding-bottom: 23px;
        margin-bottom: 30px;
      }
      .projects-left {
        max-width: 614px;
        width: 100%;
      }
      .projects-item__top {
        padding-bottom: 21px;
      }
      .projects-item__top div {
        font-size: 18px;
      }
      .projects-item__bottom > button {
        font-size: 18px !important;
      }
      .projects-item__bottom > div {
        font-size: 14px;
        max-width: 130px;
      }
      .projects-item {
        height: 100%;
        min-height: 139px;
      }
      .projects-right {
        margin-left: 39px;
      }
      .projects-right .btn {
        font-size: 14px;
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      .projects-right {
        display: none;
      }
      padding: 40px 50px 26px 50px;
      div.projects-left .projects-left__title {
        font-size: 22px !important;
        padding-bottom: 31px;
      }
      div.projects-left .projects-left__title span {
        font-size: 18px !important;
        margin-left: 13px;
      }
      div.projects-item {
        padding: 20px 20px 17px 20px;
        margin-bottom: 32px;
      }
      .projects-item__top {
        padding-bottom: 13px;
        margin-bottom: 12px;
      }
      .projects-item__top.h4 {
        font-size: 18px !important;
      }
      div.projects-item__bottom {
        display: flex;
        flex-direction: row;
      }
      div.projects-item__bottom button {
        font-size: 18px !important;
      }
      div.projects-item__bottom div {
        margin-top: 0 !important;
        font-size: 14px !important;
      }
      .projects-calendar_mobile {
        display: flex !important;
        justify-content: space-between;
        margin-bottom: 54px;
      }

      .projects-calendar_mobile span {
        color: #3a36db;
        font-size: 17px;
        font-weight: 600;
        margin-left: 11px;
      }
      .projects-calendar_mobile .outline__blue {
        font-size: 14px;
        padding: 13px 25px 13px 25px;
      }
      .content__title {
        margin-bottom: 32px;
      }
      .projects-item {
        min-height: initial;
      }
    }
    @media screen and (max-width: 1200px) {
      .projects-calendar_mobile > div {
        background: #f8f9fb;
        border-radius: 8px;
        display: flex;
        max-width: 291px;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 13px 30px 7px;
        position: relative;
      }
      .projects-calendar_mobile > div button:first-child {
        position: absolute;
        left: 15px;
        transform: translateY(calc(100% - 23px));
      }
      .projects-calendar_mobile > div button:last-child {
        position: absolute;
        right: 15px;
        transform: translateY(calc(100% - 23px));
      }
      .projects-calendar_mobile > div svg {
        width: 17px;
        height: 17px;
        margin-top: -5px;
      }
    }
    @media screen and (max-width: 1023px) {
      .projects-calendar_mobile {
        > div {
          max-width: 100%;
        }

        .btn.outline__blue {
          white-space: nowrap;
          margin-left: 15px;
          padding: 12px 29px 10px 28px;
        }
      }
      .projects-item {
        min-height: initial;
      }

      div.content__title {
        margin-bottom: 19px;
      }
      .projects-mobile__calendar {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .projects-mobile__calendar span {
        font-size: 15px;
      }
      .projects-mobile__calendar img {
        width: 20px;
        margin-right: 13px;
      }
      .projects-left {
        margin-top: 6px;
        max-width: 100%;
      }
      .projects-left .projects-left__title {
        font-size: 18px !important;
        margin-top: 17px;
        padding-bottom: 15px;
        margin-bottom: 31px;
      }
      .projects-left .projects-left__title span {
        font-size: 12px !important;
        margin-left: 6px;
      }
      .projects-right {
        display: none;
      }
      .projects-item {
        padding: 11px 10px 11px 10px;
        margin-bottom: 31px;
        background: #f8f8f8;
        box-shadow: none;
        border: none;
      }
      .projects-item__top {
        margin-bottom: 15px;
        letter-spacing: 0.4px;
      }
      .projects-item__bottom {
        flex-direction: column;
        align-items: flex-start;
      }
      div.projects-item__bottom button {
        font-size: 16px !important;
        color: #000;
        font-weight: 700;
        line-height: 20px;
      }
      div.projects-item__bottom div {
        font-size: 14px !important;
        color: #000000;
        font-weight: 400 !important;
        margin-top: 10px;
      }
      .projects-calendar_mobile {
        display: flex !important;
        margin-bottom: 25px;
        justify-content: center;
      }
      .projects-calendar_mobile > div {
        display: flex;
      }
      .projects-calendar_mobile span {
        color: #3a36db;
        font-weight: 600;
        display: block;
        font-size: 15px;
        margin-left: 8px;
      }
      .projects-item__bottom > button:hover {
        color: #3a36db;
      }
    }
    @media screen and (max-width: 500px) {
      .projects-calendar_mobile {
        flex-direction: column;

        .btn.outline__blue {
          white-space: nowrap;
          margin-left: 0;
          margin-top: 15px;
          padding: 12px 29px 10px 28px;
        }
      }
    }
  }
}
