.modal__confirm {
  padding: 74px 40px 40px;

  .confirm_btn button:first-child {
    margin-right: 20px;
  }

  .modal-header {
    span > span {
      color: #3a36db;
      padding-left: 5px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .modal__confirm {
    max-width: 95%;

    .modal-header {
      font-size: 16px;
    }
  }
}
