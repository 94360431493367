.modal__intersections {
  max-width: 492px;
  width: 100%;
  padding: 80px 80px 56px;
  display: flex;
  flex-direction: column;

  .intersections-list {
    margin-top: 22px;
  }

  .intersections-item {
    margin-bottom: 21px;
  }

  .intersections-title {
    margin-bottom: 9px;
  }

  .intersections-subItem {
    padding: 7.8px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7eb;
  }

  /* modal__changeWarehouse */
  .modal__changeWarehouse {
    max-width: 507px;
    width: 100%;
    padding: 80px 75px 80px 80px;
    display: flex;
    flex-direction: column;
  }

  .changeWarehouse_btn {
    display: flex;
    margin-top: 19px;
  }

  .changeWarehouse_btn button:last-child {
    width: 100%;
    margin-left: 20px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    max-width: 584px;
    padding: 75px 40px 40px 40px;
    .modal__changeWarehouse {
      max-width: 584px;
      padding: 74px 75px 40px 116px;
    }
    .changeWarehouse_btn {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .changeWarehouse_btn button {
      font-size: 14px;
    }
    .changeWarehouse_btn button:last-child {
      margin-left: 0;
      padding: 14px 29px 14px 28px;
      width: 209px;
      margin-right: 24px;
    }
    .modal-header {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    padding: 15px 20px 20px 20px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    > div {
      margin-bottom: 0px;
    }
    .modal-header {
      font-size: 16px;
      margin-top: 26px;
    }
    .added-btn {
      margin-top: 38px;
    }
    .intersections-list {
      margin-top: 19px;
    }
    .intersections-title {
      font-size: 14px !important;
    }
    .modal-content {
      height: fit-content;
    }
    .intersections-subItem {
      font-size: 12px !important;
      padding: 7.4px 0;
    }
  }
}
