.container {
  position: fixed;
  padding: 80px 40px;
  background: #ffffff;
  box-shadow: 10px -10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  top: 20px;
  right: 20px;
  display: flex;
  max-width: 520px;
  width: 100%;
  z-index: 9999999;
}

.alert_close {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  position: absolute;
  opacity: 0.3;
  top: 40px;
  right: 40px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    height: 21px;
    width: 2px;
    background-color: #666666;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }

  svg {
    fill: #4c6579;
  }
}
.alert-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 25px;
}

@media screen and (max-width: 991px) {
  .container {
    width: calc(100% - 30px);
    right: 15px;
  }
}
