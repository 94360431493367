.page__profile {
  .profile-naw {
    display: flex;
  }

  .profile-naw > div:first-child {
    margin-right: auto;
  }

  .profile-naw > button:last-child {
    margin-left: 30px;
    margin-top: 0;
  }

  .profile-color {
    position: relative;
    margin-right: 9px;
    margin-top: 55px;
  }

  .profile-inputs__wrap {
    display: flex;
    margin-top: 43px;
  }

  .profile-inputs__wrap > div {
    width: 36%;
  }

  .profile-inputs__wrap > div:first-child {
    margin-right: 20px;
  }

  .profile-inputs__wrap .custom-select,
  .profile-inputs__wrap .input {
    margin-bottom: 36px;
    width: 96%;
  }
  .profile-color {
    font-weight: 700 !important;
  }
  .input label {
    font-weight: 700 !important;
  }

  @media screen and (max-width: 1366px) {
    .profile-inputs__wrap > div {
      width: 48.5%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 40px 26px 50px;
    .profile-color {
      margin-top: 26px;
    }
    .profile-naw .h4 {
      font-size: 16px !important;
    }
    .profile-inputs__wrap {
      margin-top: 33px;
    }
    .profile-inputs__wrap .input {
      margin-bottom: 32px;
      width: 100%;
    }
    .profile-naw .btn {
      padding: 13px 26px 13px 26px;
    }
  }

  @media screen and (max-width: 1023px) {
    .profile-inputs__wrap {
      flex-direction: column;
    }

    .btn_eddit svg {
      margin-right: 0;
    }

    .btn_eddit {
      align-self: center;
    }
    .btn__gray.btn {
      padding: 7px 16px 6px;
    }

    .profile-inputs__wrap > div {
      width: 100%;
    }

    .profile-naw .h4 {
      font-size: 14px !important;
    }

    .profile-color {
      margin-top: 27px;
    }

    .profile-inputs__wrap {
      margin-top: 26px;
    }
    .profile-inputs__wrap .input {
      width: 100%;
    }

    .profile-submit {
      font-size: 14px !important;
      margin-top: 10px;
      padding: 14px 50px 14px 50px;
    }
    .profile-naw .h4 {
      font-size: 12px !important;
    }
    .profile-naw .h4 {
      color: #809fb8 !important;
    }
  }
}
