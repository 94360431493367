.modal__equipment {
  .rangeCalendar {
    right: -20px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1700px) {
    &.modal_added_equipment {
      max-width: 584px;
      padding: 76px 40px 40px 40px;
      width: 100%;
    }
    .modal-close {
      top: 42px;
      right: 40px;
    }
    .modal_transfer__clear {
      display: block;
      font-size: 14px;
      color: #b3b3b3;
      margin-left: auto;
    }
    .modal-header {
      font-size: 22px;
      margin-bottom: 27px;
    }
    .modal_added_equipment__items {
      margin-top: 13px;
    }
    .modal_added_equipment__items > div {
      margin-bottom: 31px;
      > div:first-child,
      > span:first-child {
        max-width: 85px;
        width: 100%;
        margin-right: 5px;
      }
    }
    .input {
      flex-direction: row;
    }
    .input label {
      margin-right: 20px;
    }
    .select-column {
      flex-direction: row;
    }
    .select-column .custom-select {
      width: 82%;
      margin-left: auto;
    }
    .input__number > span {
      max-width: 84px;
    }
    .input__number > div {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    .modal_added_equipment__items .input__number {
      margin-bottom: 19px;
    }
    .modal-status__wrap {
      margin-top: 22px;
    }
    .radio-wrap > span {
      max-width: 68px;
    }
    .added-btn {
      margin-top: 35px;
      display: flex;
      align-items: center;
    }
    .added-btn button {
      font-size: 14px;
      padding: 14px 32px 14px 32px;
    }
    .added-btn button:first-child {
      margin-right: 30px;
    }

    /* modal_transfer_equipment */
    &.modal_transfer_equipment {
      max-width: 584px;
      padding: 76px 40px 40px 40px;
      width: 100%;
    }
    .modal-close {
      top: 42px;
      right: 40px;
    }
    .modal_transfer__clear {
      display: block;
      text-align: right;
      font-size: 14px;
      color: #b3b3b3;
    }
    .modal-header {
      font-size: 22px;
      margin-bottom: 27px;
    }
    &.modal_transfer_equipment .input > label {
      max-width: 70px;
    }

    &.modal_transfer_equipment .added-btn {
      margin-top: 43px;
    }
    .input label {
      margin-bottom: 0;
    }
    .select-items {
      width: 100%;
    }
    &.modal_transfer_equipment .added-btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    &.modal_transfer_equipment .added-btn button:first-child {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    padding: 15px 15px 8px 15px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    &.modal_added_equipment > div {
      margin-bottom: 32px;
    }
    &.modal_added_equipment .modal-header,
    &.modal_transfer_equipment .modal-header {
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 20px;
    }
    &.modal_transfer_equipment {
      padding: 15px 15px 40px 15px;
    }
    .added-btn {
      margin-top: 23px;
      align-items: center;
      display: flex;
    }
    .added-btn svg {
      width: 20px;
      height: 20px;
    }
    .added-btn button {
      font-size: 14px !important;
      align-items: center;
      display: flex;
    }
    .added-btn button:first-child {
      margin-right: 16px;
    }
    .modal-content {
      height: fit-content;
    }

    .modal-status__wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: -9px;
      margin-bottom: 10px !important;
    }
    .modal-status__wrap .h6 {
      font-size: 12px !important;
    }
    .modal-status__wrap > div {
      padding: 0 0 0 2px;
      margin-top: 17px;
    }
    .modal-status__wrap > div div:last-child {
      margin-right: 57px;
    }

    .modal_added_equipment__items > div:not(:last-child) {
      margin-bottom: 31.5px;
      flex-direction: column;
      > span:first-child {
        margin-bottom: 8px;
      }
    }
    .modal_transfer__clear {
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      width: 100%;
      color: #b3b3b3;
      margin-bottom: 22px;
      display: block;
    }
    .select-items {
      width: 100%;
    }
    .input__number > div span {
      background: #f8f9fb;
      border-radius: 4px;
      // height: 34px;
    }
  }
}
