.page__reports {
  .reports-wrap {
    max-width: 532px;
  }
  .tabs-wrap > * > * {
    margin-bottom: 36px;
    max-width: 90%;
  }
  .tabs-wrap > * > *:last-child {
    margin-top: 41px;
    margin-bottom: 0;
  }
  .input.input__row label {
    font-weight: 700 !important;
  }
  .select-wrap > span {
    font-weight: 700 !important;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .content__title {
      margin-bottom: 32px;
    }
    .projects-naw span {
      font-size: 18px !important;
    }
    .tabs {
      justify-content: initial;
    }
    .tabs button:first-child {
      margin-right: 60px;
    }
    .tabs button {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .tabs {
      width: 100%;
      max-width: 100%;
    }
    .tabs-wrap > * > *:last-child {
      margin-top: 36px;
    }
    .tabs-wrap .btn {
      padding: 14px 31px 14px 31px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    .rangeCalendar {
      right: -55px;
    }
    .tabs {
      margin-bottom: 40px;
    }
    .reports-wrap {
      margin-top: -5px;
    }
    .tabs button {
      font-size: 18px;
      padding-bottom: 8px;
    }
    .reports-general .input.input__row {
      flex-direction: column;
      margin-right: 0;
      max-width: 100%;
      margin-bottom: 33px;
    }
    .reports-responsible .input.input__row {
      flex-direction: column;
      margin-right: 0;
      max-width: 100%;
      margin-bottom: 33px;
    }
    .input label {
      padding-bottom: 7px;
    }
    .tabs-wrap .btn {
      padding: 11px 31px 11px 31px;
      font-size: 14px;
      font-weight: 600;
    }
    .select-wrap {
      flex-direction: column;
      max-width: 100%;
      margin-bottom: 32px;
    }
    .select-wrap span {
      margin-bottom: 8px;
    }
    .custom-select {
      max-width: 100%;
    }
  }
}
