.addEvent-step {
  display: flex;
  max-width: 602px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 31px;
}
.addEvent-step > div {
  font-family: 'Mont';
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #868686;
}
.addEvent-step > div span {
  background: #e0e0e0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.addEvent-step > span {
  flex-grow: 1;
  height: 14px;
  margin: 0 20px;
  border-bottom: 1px dashed #e4e4e4;
}
.addEvent-step > div:last-child::before {
  display: none;
}
.addEvent-step > div.active span {
  background: #3a36db;
}

@media only screen and (min-width: 1366px) and (max-width: 1500px) {
  .addEvent-step {
    max-width: 602px;
  }
}

@media screen and (max-width: 1023px) {
  .addEvent-step {
    margin-bottom: 26px;
  }
  .addEvent-step > div {
    font-size: 10px;
  }
  .addEvent-step > span {
    height: 11px;
    margin: 0px 11px;
  }
  .addEvent-step > div span {
    width: 20px;
    height: 20px;
    font-size: 10px;
    margin-right: 2px;
    padding-top: 2.2px;
  }
  .addEvent-step > span {
    max-width: 150px;
  }
}
