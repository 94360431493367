.page__event {
  .projects-naw {
    display: flex;
    justify-content: space-between;
  }
  .projects-naw__title {
    height: max-content;
  }
  .projects-naw__title svg {
    margin-right: 10px;
    margin-bottom: -4px;
  }
  .projects-naw > a,
  .projects-naw > a span,
  .projects-naw > a path {
    transition: all 0.4s;
  }
  .projects-naw > a:hover {
    border-color: #3a36db;
    color: #3a36db;
  }
  .projects-naw > .projects-naw__title:hover span {
    color: #3a36db;
  }
  .projects-naw > .projects-naw__title:hover path {
    stroke: #3a36db;
  }

  .projectsAll-filter {
    max-width: 480px;
    margin-bottom: 57px;
    margin-top: 50px;
  }
  .projectsAll-filter > div:not(:last-child) {
    margin-bottom: 36px;
  }
  .projectsAll-filter label {
    max-width: 175px;
    width: 100%;
  }
  .projectsAll-table {
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);
    border-radius: 8px;
    min-width: 712px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex-grow: 1;
  }
  .input.input__row label,
  .custom-table tr td:first-child,
  .select-wrap > span {
    font-weight: 600;
  }

  .projectsAll-filter .input > span:first-child {
    max-width: 190px;
    width: 100%;

    label {
      display: inline;
    }
  }
  .projectsAll-filter .input label {
    width: fit-content;
  }
  .projectsAll-filter > div:last-child label::before {
    right: 30%;
  }
  .custom-table td {
    padding: 19px 19px 18px;
  }
  .projectsAll-table {
    max-width: 1444px;
    width: 100%;
  }
  .input.input__row label {
    font-weight: 700 !important;
  }
  .custom-table tr td:first-child {
    transition: 0.4s;
  }
  .custom-table tr td:first-child:hover {
    color: #3a36db;
    cursor: pointer;
  }
  .rangeCalendar {
    top: 0px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .projects-naw span {
      font-size: 16px !important;
    }
    .projectsAll-filter {
      margin-top: 34px;
    }
    .projectsAll-table {
      min-width: initial;
      width: 100%;
    }
    .projects-naw span {
      font-size: 18px !important;
    }
    .projectsAll-filter {
      margin-top: 54px;
    }
    .custom-table th {
      white-space: nowrap;
    }
    .custom-table td {
      padding: 19px 14px 19px 11px;
    }
  }

  @media screen and (max-width: 768px) {
    .custom-table tr td:first-child:hover {
      color: #000;
    }
    .projectsAll-filter > div:not(:last-child) {
      margin-bottom: 26px;
    }
    .rangeCalendar {
      right: 0;
    }
    .projectsAll-filter .input label {
      margin-right: 0;
      font-size: 12px;
      max-width: 106px;
    }
    .projectsAll-filter .input input::placeholder {
      font-size: 12px;
    }
    .projectsAll-filter .select-wrap span {
      font-size: 12px !important;
      max-width: 101px;
    }
    .projectsAll-filter .custom-select .select-selected {
      font-size: 12px;
      padding: 10px 11px 14px;
    }
    .projectsAll-filter .input::placeholder {
      font-size: 12px;
    }
    .projectsAll-table {
      margin-top: 90px;
      overflow-x: scroll;
      max-width: calc(100vw - 40px);
      padding-bottom: 22px;
      min-width: initial;
      margin-right: 0;
    }
    .big-table th {
      font-size: 12px;
      padding: 16px 16px 17px 16px;
    }
    .big-table tr th:nth-child(1) {
      min-width: 150px;
    }
    .big-table tr th:nth-child(2) {
      min-width: 90px;
    }
    .big-table td {
      padding: 13px 16px 15px 7px;
      font-size: 12px;
    }
    .projectsAll-filter .input > span:first-child {
      max-width: fit-content;
      width: 100%;
    }
    .projectsAll-filter {
      margin-top: 22px;
      max-width: 100%;
      margin-bottom: 0;
    }
    .projectsAll-filter .input label {
      padding-bottom: 10px;
      font-size: 14px;
      white-space: nowrap;
    }
    .projectsAll-filter .input input::placeholder {
      font-size: 14px;
    }
    div.input.input__row {
      flex-direction: column;
    }
    .projectsAll-filter .input label {
      max-width: 100%;
    }
    .projectsAll-filter > div:not(:last-child) {
      margin-bottom: 27px;
    }
    .projectsAll-table {
      margin-top: 36px;
      padding-bottom: 26px;
    }
    .big-table td {
      padding: 11px 16px 11px 16px;
    }
    .projectsAll-filter > div:last-child label::before {
      right: -12px;
    }
  }
}
