.input-file__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
}

.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-icon-wrapper {
  height: 49px;
  width: auto;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input__file-button-text {
  height: 44%;
  overflow: hidden;
}

.input__file-button {
  width: auto;
  max-width: 100%;
  padding: 0px 31px 0px 30px;
  height: 49px;
  background: #fff;
  color: #3a36db;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #cecdf6;
  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
