.project_all {
  .projectsAll-table {
    max-height: 500px;
    overflow: auto;
  }
  .projects-naw {
    display: flex;
    justify-content: space-between;
    > *:first-child {
      margin-right: auto;
    }
    .btn {
      margin-left: 19px;
    }
  }
  .projects-naw__title {
    height: max-content;
  }
  .projects-naw__title svg {
    margin-right: 10px;
    margin-bottom: -4px;
  }
  .projects-naw .projects-naw__print {
    width: 52px;
    padding: 0;
    margin-left: 19px;
  }
  .projects-naw .projects-naw__print svg {
    margin: auto;
  }
  .projects-naw > a,
  .projects-naw > a span,
  .projects-naw > a path {
    transition: all 0.4s;
  }
  .projects-naw > a.outline__blue:hover {
    border-color: #3a36db;
    color: #3a36db;
  }
  .projects-naw > .projects-naw__title:hover span {
    color: #3a36db;
  }
  .projects-naw > .projects-naw__title:hover path {
    stroke: #3a36db;
  }
  .projects__title {
    color: #000000;
    margin-top: 52px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .projects__title > div {
    display: flex;
  }
  .projects__title > div div:first-child {
    margin-right: 41px;
  }
  .projects__title > div div span:first-child {
    margin-right: 5px;
  }
  .projects__all {
    color: #000000;
    margin-top: 58px;
    margin-bottom: 18px;
  }
  .projects-tables__itemWrap {
    max-width: 710px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 20px;
  }
  .projects-tables__item {
    padding: 25px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 710px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex-grow: 1;
  }
  .projects-tables__item > .projects-table__title {
    margin-left: 16px;
    margin-bottom: 18px;
  }
  .projects-tables {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
    margin-right: -20px;
    justify-content: space-between;
  }
  .projects-tables td > div {
    color: #868686;
  }
  .projects__equipment td:first-child {
    width: 270px;
  }
  .projects-equipment {
    padding: 14px 0;
  }
  .projects-equipment .projects-tableInfo__title > div {
    display: flex;
    align-items: center;
  }
  .projects-equipment__title {
    padding-bottom: 7px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 7px;
    margin-top: 19px;
  }
  .projects-equipment__text {
    max-width: 638px;
  }
  .projects-tables__info {
    display: flex;
  }
  .projects-tables__info > div > span:first-child {
    margin-right: 7px;
  }
  .projects-tables__info > div {
    margin-left: 30px;
  }

  div.projects-tableInfo__title {
    margin-bottom: 17px;
    margin-right: auto;
    margin-left: 0;
  }
  .projects-tables__info .projects-tableInfo__date {
    display: flex;
    justify-content: space-between;
    > div {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
      > span:first-child {
        margin-right: 5px;
      }
    }
  }
  .projects-tables__itemWrap table td:first-child {
    width: 35%;
  }
  .projects-additionally {
    margin-top: 18px;
  }
  .projects-additionally > div {
    display: flex;
    font-size: 14px;
  }
  .projects-additionally > div:first-child {
    margin-bottom: 15px;
  }
  .projects-additionally span {
    font-size: 14px;
    color: #111827;
    margin-right: 15px;
    font-weight: 600;
    width: 100%;
    max-width: 91px;
    display: block;
  }
  .custom-table tr td:first-child {
    font-weight: 700 !important;
  }
  .projects-additionally span {
    font-weight: 700;
  }

  @media screen and (max-width: 1900px) {
    .projects-tables__itemWrap {
      max-width: initial;
      margin-right: 0;
      margin-top: 5px;
    }
    .projectsTables__wrap .projects-tables__item {
      min-width: initial;
    }
    .projectsTables__wrap .projects-tables__itemWrap {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 1366px) {
    .projects__equipment td:first-child {
      width: auto;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .projects-naw .projects-naw__title {
      font-size: 18px !important;
    }
    .projects-tables__item > .projects-table__title {
      font-size: 18px !important;
    }
    .projects__title {
      margin-bottom: 19px;
    }
    .projects__all.h4 {
      font-size: 18px !important;
    }
    div.projects-tableInfo__title {
      font-size: 18px !important;
    }
    .projects-tableInfo__date {
      flex-direction: column;
    }
    div.projects-tableInfo__title {
      margin-right: initial;
    }
    .projects-tables__info .projects-tableInfo__date {
      margin-left: 10%;
    }
    .projects-tableInfo__date div:first-child {
      margin-bottom: 7px;
    }
    .projects-tables__info {
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .projects-naw span {
      font-size: 18px !important;
    }
    .projects__title.h3 {
      margin-top: 32px;
    }
    .projects__title {
      flex-direction: column;
      align-items: initial;
    }
    .projects__title span {
      margin-bottom: 19px;
    }
    .projects__title > div div:first-child {
      margin-right: 21px;
    }
    .projects-tables__item {
      min-width: initial;
    }
    .custom-table.oneTdBold td:first-child {
      width: 42%;
    }
    .projects-tables__item .h4 {
      font-size: 18px !important;
    }
    .projects__all.h4 {
      font-size: 18px !important;
    }
    .projects-tables__info .projects-tableInfo__date {
      flex-direction: column;
      margin-right: 20px;
    }
    .projects-tableInfo__date > div {
      margin-bottom: 7px;
    }
    .projects-tableInfo__date > div span:first-child {
      margin-right: 6px;
    }
    .wrap-big-table {
      margin-top: 14px;
    }
    .projects-naw span {
      font-size: 14px !important;
    }
    .projects__equipment .custom-table tr th:nth-child(2) {
      width: 58%;
    }
  }

  @media screen and (max-width: 1023px) {
    .projects-equipment {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .projects-additionally {
      margin-left: 15px;
    }
    .projects__title {
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px !important;
      margin-top: 12px;
    }
    .projects__title div span {
      font-size: 12px !important;
      line-height: 192%;
    }
    .projects__title > div {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
    }
    .projects__title > div div:first-child {
      margin-right: 41px;
    }
    .projects__title > div div span:first-child {
      margin-right: 5px;
    }
    .projects-tables__item .projects-table__title {
      font-size: 16px !important;
      margin-left: 0px;
      margin-bottom: 9px;
    }
    .projects-tables__item {
      padding: 6px 0px 19px 0px;
      box-shadow: none;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 18px;
      min-width: initial;
    }
    .projects-tables__itemWrap {
      margin-top: 0;
      margin-right: 20px;
    }
    .projects-tables__itemWrap .projects-tables__item {
      margin-right: 0;
    }
    .projects-tables__itemWrap .custom-table td {
      padding: 12px 16px 6px 7px;
    }
    .projects-tables__itemWrap .projects-tables__item:first-child {
      padding: 0 0px 12px 0px;
    }
    .projects-tables__itemWrap .projects-table__title {
      margin-bottom: 2px;
    }
    .custom-table td {
      font-size: 12px;
      padding: 12px 16px 12px 7px;
    }
    .custom-table.oneTdBold td:first-child {
      vertical-align: baseline;
      width: 39.2%;
    }
    .projects__equipment .projects-tables__info {
      flex-direction: column;
    }
    div.projects__all {
      font-size: 16px !important;
      margin-top: 46px;
      margin-bottom: 41px;
    }

    .projects-tables__info > div {
      display: flex;
      font-size: 12px;
      margin-left: 15px;
      line-height: 154%;
    }
    .projects-tables__info > div span {
      font-size: 12px;
    }
    .projects-tables__info > div div:first-child {
      margin-right: 41px;
    }
    .projects-tables__info div span:first-child {
      margin-right: 5px;
      width: 150px;
      line-height: 205%;
    }

    .projects__equipment .projects-tableInfo__title {
      font-size: 16px !important;
      margin-bottom: 21px;
    }
    .projects__equipment .projects-tableInfo__title {
      flex-direction: column;
    }
    .projects__equipment .projects-equipment__title {
      font-size: 16px !important;
    }
    .projects__equipment .projects-equipment__text {
      font-size: 12px !important;
    }
    .wrap-big-table {
      overflow-x: scroll;
      max-width: calc(100vw - 40px);
      padding-bottom: 11px;
    }
    table.big-table {
      margin-top: 16px;
      margin-left: 15px;
    }
    .big-table th {
      font-size: 12px;
      padding: 16px 16px 17px 16px;
    }
    .big-table tr th:nth-child(1) {
      min-width: 99px;
    }
    .big-table tr th:nth-child(2) {
      min-width: 200px;
      width: 100%;
    }
    .big-table td {
      padding: 13px 16px 15px 7px;
      font-size: 12px;
    }
    .projects__title > div div {
      display: flex;
    }
    .projects__title > div div span:first-child {
      min-width: 115px;
      max-width: 115px;
      width: 100%;
      display: block;
    }
    .projects-additionally > div,
    .projects-additionally span {
      font-size: 12px;
    }
  }
}
