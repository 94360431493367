.project_info {
  .project_info__title {
    font-weight: 700;
    font-size: 22px;
    color: #000000;
  }

  .project_info-table_wrap {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    .project_info-table_title {
      font-size: 18px;
      color: #809fb8;
      margin-left: 16px;
      margin-bottom: 18px;
    }
    .custom-table tr td:first-child {
      color: #111827;
      font-weight: 700;
    }
    .custom-table tr td:last-child {
      color: #6b7280;
    }

    > div {
      padding: 25px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      height: fit-content;
    }
    > div:first-child {
      width: 49.5%;
    }
    > div:last-child {
      width: 48.5%;
    }
  }
  .project_info-booking_title {
    margin-top: 30px;
    color: #000000;
    font-weight: 700;
    font-size: 22px;
  }
  .project_info-booking {
    margin-top: 27px;
    padding: 25px;
    .booking-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
      .booking-header-title {
        color: #809fb8;
        font-size: 18px;
      }
      .booking-header-date {
        display: flex;
        > div:last-child {
          margin-left: 30px;
        }
        div {
          font-size: 14px;
          color: #6b7280;
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            display: block;
            color: #111827;
            margin-right: 10px;
          }
        }
      }
    }
    tr td {
      color: #868686;
      font-size: 14px;
    }
    tr td:first-child {
      color: #000000;
    }
  }
  .outline__blue {
    margin-top: 49px;
  }
  .btn_submit {
    margin-top: 30px;
  }
  @media screen and (max-width: 1420px) {
    .project_info-table_wrap {
      flex-direction: column;
      > div:last-child {
        width: 71%;
      }
      > div:first-child {
        margin-bottom: 28px;
        width: 71%;
      }
    }
    .project_info-booking .booking-header .booking-header-title {
      margin-right: 94px;
    }
    .project_info-booking .booking-header {
      justify-content: initial;
    }
    .booking-header-date {
      flex-direction: column;
    }
    .project_info-booking
      .booking-header
      .booking-header-date
      > div:last-child {
      margin-left: 0;
      margin-top: 11px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .project_info-table_wrap {
      flex-direction: column;
      > div:last-child {
        width: 100%;
      }
      > div:first-child {
        margin-bottom: 28px;
        width: 100%;
      }
    }
    .outline__blue {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 1023px) {
    .project_info-table_wrap {
      flex-direction: column;
      > div:last-child {
        width: 100%;
      }
      > div:first-child {
        margin-bottom: 28px;
        width: 100%;
      }
      .custom-table tr td:first-child {
        width: 50%;
      }
    }
    .project_info__title {
      font-size: 18px;
    }
    .project_info-table_wrap .project_info-table_title {
      font-size: 16px;
    }
    .project_info-table_wrap > div {
      padding: 0;
      box-shadow: none;
    }
    tr td {
      font-size: 12px;
    }
    .custom-table td,
    .custom-table th {
      padding: 16px 16px 16px 8px;
    }
    .project_info-booking_title {
      font-size: 18px;
    }
    .project_info-booking {
      margin-top: 8px;
      padding: 15px;
    }
    .project_info-booking .booking-header {
      margin-bottom: 20px;
      flex-direction: column;
      .booking-header-title {
        font-size: 16px;
      }
      .booking-header-date div {
        font-size: 12px;
        span {
          font-size: 12px;
        }
      }
      .booking-header-date {
        margin-top: 20px;
        > div:last-child {
          margin-top: 5px;
        }
      }
    }
    .project_info-table_wrap .project_info-table_title {
      margin-left: 0;
    }
    .wrapper_table {
      max-width: 500px;
      width: 100%;
      overflow-x: auto;
    }
  }
}
