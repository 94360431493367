.transfer-page {
  .transfer-addWrap__table {
    overflow: auto;
    max-height: 500px;
  }
  .transfer-tabs {
    max-width: 855px;
    margin-bottom: 30px;
  }
  .transfer-wrap {
    display: flex;
    .transfer-left {
      max-width: 480px;
      width: 100%;
      margin-right: 60px;
      form > * {
        margin-bottom: 25px;
      }
    }
    .transfer-right {
      max-width: 803px;
      width: 100%;
      max-height: 600px;
      overflow: auto;
      .transfer-item {
        border: 1px solid #e0e0e0;
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
          0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 21px 20px 20px;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        &__header {
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
        }
        &__title {
          margin-top: 15px;
          padding-bottom: 12px;
          border-bottom: 1px solid #e0e0e0;
          margin-bottom: 15px;
        }
        &__info {
          margin-top: 15px;
          > span:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
    .transfer-calendar {
      margin-left: 40px;
      .btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .input > span:first-child {
    max-width: 180px;
    width: 100%;
  }
  .transfer-addWrap {
    max-width: 1002px;
    form > div {
      display: flex;
      margin-bottom: 25px;
      > * {
        max-width: 480px;
        width: 100%;
        margin-right: 42px;
        &:last-child {
          margin-right: 0;
        }
      }
      &:last-child {
        border-top: 1px solid #e0e0e0;
        padding-top: 17px;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .transfer-addWrap > form > div {
      flex-direction: column;
      > * {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .transfer-tabs button {
      font-size: 18px;
    }
    .rangeCalendar {
      right: 0;
    }
    .transfer-wrap {
      flex-wrap: wrap;
      .transfer-left {
        order: 1;
        max-width: 100%;
        form > * {
          flex-direction: column;
          > span:first-child {
            margin-bottom: 8px;
          }
        }
      }
      .transfer-right {
        order: 3;
        flex-grow: 1;
        max-width: 100%;
      }
      .transfer-calendar {
        order: 2;
      }
      .transfer-calendar_mobile {
        align-items: flex-start;
        max-width: 100%;
      }
    }
    .transfer-addWrap {
      > form > div > *.input__row {
        flex-direction: column;
        > span:first-child {
          margin-bottom: 8px;
        }
      }
      .custom-table tr {
        td:nth-child(5),
        th:nth-child(5) {
          display: none;
        }
      }
    }
    div.mobile-icon_transfer {
      width: 36px;
      height: 36px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 1px 9px rgb(0 0 0 / 8%);
      cursor: pointer;
      margin-top: 20px;
      svg {
        width: 15px;
        height: 11px;
        margin-left: 5px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .transfer-tabs {
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 15px;

      button {
        min-width: 200px;
      }
    }
    .transfer-wrap {
      flex-direction: column;
      .transfer-calendar {
        display: none;
      }
    }
    .transfer-calendar_mobile {
      margin-bottom: 30px;
      justify-content: space-between;
      > div {
        background: #f8f9fb;
        border-radius: 8px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 13px 19px 7px 19px;
        position: relative;
        span {
          color: #3a36db;
          font-weight: 600;
          display: block;
          font-size: 15px;
          margin-left: 8px;
        }
      }
    }
    .transfer-calendar_mobile > div button:first-child {
      position: absolute;
      left: 15px;
      transform: translateY(calc(100% - 23px));
    }
    .transfer-calendar_mobile > div button:last-child {
      position: absolute;
      right: 15px;
      transform: translateY(calc(100% - 23px));
    }
    .transfer-calendar_mobile > div svg {
      width: 17px;
      height: 17px;
      margin-top: -5px;
    }
  }

  @media screen and (max-width: 410px) {
    .rangeCalendar {
      right: -20px !important;
    }
  }
}
