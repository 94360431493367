.modal__deleteBooking {
  /* modal__deleteBooking */
  max-width: 746px;
  width: 100%;
  padding: 80px 63px 80px;
  display: flex;
  flex-direction: column;

  .deleteBooking_btn {
    margin-top: 20px;
  }

  .deleteBooking_btn button:last-child {
    margin-left: 26px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    max-width: 585px;
    padding: 74px 96px 40px;
    .deleteBooking_btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .deleteBooking_btn button:last-child {
      margin-right: 26px;
      margin-left: 0;
    }
    .deleteBooking_btn button {
      font-size: 14px;
      padding: 14px 31px 14px 31px;
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 50px 20px 40px 20px;
    max-width: 335px;
    width: 100%;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    .modal .btn {
      font-size: 14px;
    }
    .modal-content {
      height: fit-content;
    }
    .modal-header {
      font-size: 16px;
      margin-bottom: 11px;
    }
    .deleteBooking_btn .btn {
      padding: 14px 29px 14px 28px;
      font-size: 14px;
    }
    .deleteBooking_btn > button:first-child {
      margin-right: 26px;
    }
    .deleteBooking_btn > button:last-child {
      width: 144px;
      margin-left: 0;
    }
  }
}
