.addEvent-wrap__step1 {
  display: flex;
  align-items: flex-start;

  .addEvent-wrap__left {
    max-width: 504px;
    flex-grow: 1;
    box-shadow: none;
    padding: 0;
  }

  .addEvent-left__select > span {
    font-family: 'Mont';
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 7px;
    display: flex;
  }
  .addEvent-left__select > div {
    max-width: 100%;
  }
  .addEvent-left__checkbox {
    margin-bottom: 21px;
  }
  .addEvent-left__checkbox > label {
    padding-left: 30px;
  }
  .addEvent-wrap__add {
    max-width: 584px;
    width: 100%;
    background: #f8f9fb;
    border-radius: 8px;
    margin-left: 80px;
    padding: 40px;
  }

  .addEvent-add__items > div:first-child {
    margin-top: 21px;
  }

  .addEvent-add__items > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .addEvent-add__btn {
    display: flex;
    margin-top: 48px;
    .outline__blue {
      margin-top: 0;
    }
  }

  .addEvent-add__btn > button:first-child {
    margin-right: 18px;
  }

  .addEvent-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .addEvent-title__color {
    position: relative;
    margin-right: 9px;
  }

  .addEvent-radio {
    display: flex;
  }

  .addEvent-radio span {
    margin-right: 38px;
    max-width: initial;
    width: initial;
  }

  .addEvent-radio > div {
    width: 308px;
  }

  .addEvent-client {
    display: none;
  }
  .addEvent-employee {
    display: none;
  }
  .addEvent-client.active {
    display: block;
  }
  .addEvent-employee.active {
    display: block;
  }
  .input label {
    font-weight: 700 !important;
  }
  .input-icon__calendar {
    position: relative;
    .rangeCalendar {
      position: absolute;
      background: #ffffff;
      right: -150px;
    }
  }
  .outline__blue {
    margin-top: 30px;
  }

  @media screen and (max-width: 1500px) {
    .addEvent-wrap__left {
      flex: 1;
    }
    .addEvent-wrap__add {
      flex: 1;
    }
    .input-icon__calendar {
      .rangeCalendar {
        right: -20px;
      }
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .addEvent-wrap__add {
      max-width: 456px;
      margin-left: 40px;
      padding: 30px 31px 40px 31px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .projects-naw span {
      font-size: 18px !important;
    }
    .addEvent-wrap__left .btn {
      font-size: 14px;
      padding: 14px 31px 14px 31px;
    }
    .content__title {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 1023px) {
    .content__title {
      margin-bottom: 23px;
    }
    .addEvent-wrap__left {
      max-width: 100%;
    }
    .addEvent-wrap__left .h3 {
      font-size: 18px !important;
      margin-bottom: 25px !important;
    }
    .addElement {
      margin-top: 17px;
    }
    .addEvent-wrap__left > div {
      margin-bottom: 6px;
    }
    .addEvent-wrap__left .btn.outline__blue {
      padding: 13px 31px 13px 31px;
      font-size: 14px;
      margin-top: 25px;
    }
  }
}
