.modal__added {
  /* modal__add */
  max-width: 584px;
  width: 100%;
  padding: 40px 40px 8px 40px;
  display: flex;
  flex-direction: column;
  .modal-header {
    margin-top: 34px;
  }
  .modal__added__items > div {
    margin-bottom: 31px;
  }
  .modal-users__items > div:last-child {
    margin-bottom: 0;
    margin-top: 45px;
  }

  .added-btn {
    align-items: center;
    display: flex;
    margin-top: 48px;
  }

  .added-btn button:last-child {
    margin-left: 20px;
  }

  .added-radio > span {
    width: auto;
    max-width: initial;
  }
  .added-radio > div {
    width: 306px;
  }
  .added-color {
    padding-top: 4px;
  }
  .users-btn > button {
    &:first-child {
      margin-right: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .added-btn button {
      height: 49px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    padding: 15px 18px 8px 15px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    .modal .btn {
      font-size: 14px;
    }
    .modal__added__items > div {
      margin-bottom: 32px;
    }
    .modal-header {
      font-size: 16px;
      margin-top: 26px;
    }
    .added-btn {
      margin-top: 38px;
    }
    .modal-content {
      height: fit-content;
    }
    .modal-status__wrap {
      display: flex;
      justify-content: space-between;
    }
    .modal-status__wrap .radio-wrap {
      max-width: 50%;
      flex-direction: column;
    }
    .modal-status__wrap .radio-wrap .h6 {
      font-size: 14px !important;
    }
    .modal-status__wrap .radio-wrap > div {
      padding: 0 0 0 2px;
      margin-top: 12px;
    }
    .modal-status__wrap .radio-wrap > div .custom-radio:first-child {
      margin-bottom: 30px;
    }
    .modal-status__wrap .profile-color {
      display: flex;
      flex-direction: column;
      margin-right: 39px;
    }
    .modal-status__wrap .profile-color span {
      margin-top: 16px;
      margin-right: 15px;
    }
  }
}
