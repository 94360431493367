.page__categories {
  .categories-item__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    max-height: 480px;
    overflow: hidden;
    transition: all 0.4s;
  }
  .categories-left {
    flex-grow: 1;
  }

  .categories-item {
    margin-bottom: 59px;
    &.categoriesTab__active {
      .categories-item__wrap {
        max-height: 0;
      }
      .categories-item__title svg {
        transform: rotate(180deg);
      }
    }
  }
  .categories-items {
    max-width: 1287px;
  }
  .categories-item__title {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 50px;
    svg {
      transition: all 0.4s;
    }
  }
  .categories-item__title button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f4fa;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-left: auto;
  }

  .categories-wrap__left {
    padding: 25px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 737px;
    width: 100%;
    max-height: 474px;
    overflow: auto;
    margin: 2px 2px 4px;
  }
  .categories-wrap__left th,
  .categories-wrap__left td {
    font-weight: 600;
  }
  .oneTd42p td:first-child,
  .oneTd42p th:first-child {
    width: 42.4%;
  }
  .categories-new {
    background-color: #f8f9fb;
    margin-left: 60px;
    flex-grow: 1;
    max-width: 470px;
    padding: 40px;
  }
  .categories-new__title {
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 40px;
  }
  .categories-new__items > div {
    margin-bottom: 31px;
  }
  .categories-new__items > div:last-child {
    margin-bottom: 0;
    margin-top: 36px;
  }
  .categories-new__items > div > span,
  .categories-new__items > div > label {
    max-width: 85px;
    width: 100%;
  }
  .categories-new__btn {
    display: flex;
  }
  .categories-new__items /* global */.btn__red {
    margin-right: 23px;
  }
  .categories-new__btn > *:first-child {
    margin-right: 30px;
  }

  .categories__clear {
    margin-left: auto;
    display: block;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .categories-item__title {
      margin-bottom: 30px;
    }
    .categories-wrap__left {
      max-width: 526px;
    }
    .categories-new {
      margin-left: 40px;
      padding: 31px 30px 31px 30px;
    }
    .categories-new__title {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    .categories-new {
      display: none;
    }
    .add__btn {
      display: flex !important;
      align-items: center;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .projects-naw span {
      font-size: 18px !important;
    }
    .addEvent-wrap__left .btn {
      font-size: 14px;
      padding: 14px 31px 14px 31px;
    }
    .content__title {
      margin-bottom: 32px;
    }
    .add__btn {
      display: flex;
    }
    .add__btn span {
      margin-right: 7px;
      font-size: 14px;
    }
    .categories-item__title {
      border-bottom: none;
      margin-bottom: 0;
    }
    .categories-item__title {
      padding-bottom: 16px;
    }
    .categories-item__wrap {
      margin-top: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    .categories-wrap__left {
      max-width: 100%;
    }
    .content__title {
      margin-bottom: 26px;
    }
    .categories-item__title {
      font-size: 18px !important;
      border-bottom: none;
      padding-bottom: 21px;
      margin-bottom: 0px;
    }
    .categories-item__title button {
      width: 30px;
      height: 30px;
    }
    .categories-item__title button svg {
      width: 10px;
      height: 7px;
    }
    .add__btn {
      display: flex;
      align-items: center;
    }
    .add__btn span {
      font-size: 14px !important;
      margin-right: 10px;
      display: block;
    }
    .categories-item__wrap {
      margin-top: 15px;
      overflow: scroll;
      max-width: 100%;
      width: 100%;
      margin-right: -15px;
      padding-bottom: 21px;
    }
    .categories-wrap__left {
      padding: 15px;
      max-height: 187px;
      overflow: scroll;
      min-width: calc(100vw + 70px);
      margin-right: -15px;
    }
    .categories-wrap__left::-webkit-scrollbar-track {
      margin-top: 14px;
    }
    .categories-item__wrap::-webkit-scrollbar-track {
      margin-top: 14px;
    }
    .custom-table th {
      font-size: 12px;
    }
    .custom-table td {
      font-size: 12px;
    }
    .categories-item__wrap {
      padding-left: 1px;
      padding-top: 1px;
    }
  }
}
