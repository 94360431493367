.auth {
  background-color: #f1f4fa;
  min-height: 100vh;
  display: flex;
}
.auth-left {
  padding: 117px 130px 0 100px;
  max-width: 37.5%;
  position: relative;
  z-index: 1;
}
.auth-left::before {
  content: '';
  position: absolute;
  right: -40px;
  bottom: 0;
  background: url('../../../assets/img/bg/Login.png') no-repeat right bottom;
  background-size: contain;
  width: calc(100% + 40px);
  max-height: 633px;
  height: 100%;
  z-index: -1;
}
.auth-right__title {
  color: #000000;
  border-bottom: 3px solid #000000;
  margin-bottom: 62px;
  display: inline-block;
  padding-bottom: 13px;
}
.auth-left__subtitle {
  margin-bottom: 81px;
}
.auth-left__text {
  padding-left: 19px;
  max-width: 87%;
  border-left: 2px solid #3a36db;
  margin-top: 29px;
}
.auth-right {
  background-color: #fff;
  flex-grow: 1;
  border-radius: 40px 0px 0px 40px;
  padding: 120px 0 0 200px;
  position: relative;
  z-index: 2;
  min-width: 62.5%;
}
.auth-forms {
  max-width: 504px;
}
.auth-forms > form {
  display: flex;
  flex-direction: column;
}
.auth-forms {
  :global {
    .input:not(:last-child),
    .checkbox {
      margin-bottom: 30px;
    }
    .btn {
      align-self: start;
    }
  }
}

@media screen and (max-height: 1366px) {
  .auth-left {
    max-width: 39.8%;
    padding: 104px 105px 0 80px;
  }
  .auth-left__subtitle {
    margin-bottom: 64px;
  }
  .auth-left__text {
    max-width: 98%;
  }
  .auth-right {
    min-width: 60.2%;
    padding: 102px 0 0 100px;
  }
  .auth-left::before {
    background: url('../../../assets/img/bg/Login_1366.png') no-repeat right
      bottom;
  }
}

@media screen and (max-width: 1300px) {
  .auth-left::before {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .auth {
    flex-direction: column;
  }
  .auth-left {
    max-width: 100%;
    padding: 39px 20px 0 20px;
  }
  .auth-left__subtitle {
    margin-bottom: 28px;
  }
  .auth-left__text {
    margin-top: 15px;
  }
  .auth-right {
    position: relative;
    padding: 31px 21px 21px 21px;
    margin-top: 28px;
    border-radius: 20px 20px 0px 0px;
    background: #f1f4fa;
  }
  .auth-right .auth-right__title {
    font-size: 24px !important;
    border: none;
    margin-bottom: 6px;
  }
  .auth-right {
    :global {
      .btn {
        padding: 15px 50px 13px 50px;
        font-size: 14px;
      }
    }
    .form-inputs {
      :global {
        .input:not(:last-child) {
          margin-bottom: 25px;
        }
        .input:last-child {
          margin-bottom: -5px;
        }
      }
    }
  }
  .auth-forms {
    max-width: 100%;
  }
  .auth-right::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: url('../../../assets/img/bg/Login_mobile.png') no-repeat right
      bottom;
    background-size: contain;
    width: 100%;
    max-height: 58%;
    height: 100%;
    z-index: -1;
  }
}

@media screen and (max-height: 767px) {
  .auth-left::before {
    display: none;
  }
}

/*  media height */
@media screen and (max-height: 874px) {
  .auth-right::before {
    display: none;
  }
}
