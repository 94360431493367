.modal__transfer {
  max-width: 584px;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  form > * {
    margin-bottom: 27px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .rangeCalendar {
    right: 0;
    top: 0;
    transform: translatey(-10%);
  }
  .transfer-delete {
    border-top: 1px solid #e0e0e0;
    button {
      padding: 25px 0 0;
    }
  }
  .added-btn {
    margin-bottom: 17px;
    button {
      font-size: 14px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .modal-header {
      font-size: 22px;
      margin-bottom: 22px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 360px;
    width: 100%;
    padding: 15px 18px 8px 15px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    .modal .btn {
      font-size: 14px;
    }
    .modal-header {
      font-size: 16px;
      margin-top: 26px;
    }
    .added-btn button {
      font-size: 14px;
    }
    .modal-content {
      height: fit-content;
    }
    .rangeCalendar {
      right: -15px;
    }
  }
}
