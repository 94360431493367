.addEvent-wrap__step2 {
  .rangeCalendar {
    position: absolute;
    right: -150px;
    background: #fff;
  }
  .addEvent-wrap {
    display: flex;
    align-items: initial;
  }
  .addEvent-wrap__left,
  .addEvent-wrap__right {
    padding: 24px 25px 28px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
  }
  .addEvent-wrap__left {
    max-width: 530px;
    margin-right: 30px;
  }
  .addEvent-wrap__left > div {
    margin-bottom: 36px;
  }
  .addEvent-wrap__right > * {
    display: flex;
    margin-bottom: 59px;
  }
  .addEvent-wrap__right > * > span {
    max-width: 270px;
    width: 100%;
    margin-right: 5px;
  }
  .addEvent-wrap__right > * > div {
    display: flex;
  }
  .addEvent-right__checkboxes > div {
    margin-top: 0;
    margin-right: 30px;
  }
  .addEvent-right__textarea textarea {
    height: 90px;
    max-width: 559px;
    width: 100%;
  }
  .addEvent-right__textarea textarea::placeholder {
    font-size: 14px;
  }

  .addEvent-right__textarea {
    margin-bottom: 34px;
  }
  /* addEvent-booking */
  .addEvent-booking {
    margin-bottom: 30px;
  }
  .addEvent-booking__tabs {
    display: grid;
    grid-template-columns: 1fr 275px;
    align-items: center;
    margin-bottom: 18px;
  }
  .addEvent-booking__tabs > * {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
  }
  .addEvent-booking__tabs > *:not(:last-child) svg {
    margin-right: 15px;
  }
  .addEvent-booking__tabs > *:last-child {
    margin: auto 0 10px auto;
  }

  .addEvent-tab__wrap {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 9px;
    padding: 25px;
  }
  .addEvent-tab__wrap > div {
    max-width: 1393px;
    width: 100%;
  }
  .addEvent-tab__wrap > div:nth-child(1),
  .addEvent-tab__wrap > div:nth-child(2) {
    max-width: 480px;
    width: 100%;
  }
  .addEvent-tab__wrap > div {
    margin-bottom: 36px;
  }
  .addEvent-tab__row > div:first-child {
    flex: 480px;
    margin-right: 30px;
  }
  .addEvent-tab__row > div:last-child {
    flex: 365px;
  }
  .addEvent-tab__row > div:last-child span {
    max-width: 60px;
    width: 100%;
  }
  .adding-element__left {
    border-right: none;
  }
  .input.input__row label {
    font-weight: 700 !important;
  }
  .addEvent-wrap__right .h6 {
    font-weight: 700 !important;
  }

  .select-wrap .h6 {
    font-weight: 700 !important;
  }
  .addingElement-wrap .h6 {
    font-weight: 700 !important;
  }
  .adding-element > div {
    max-width: 588px;
    width: 50%;
    position: relative;
  }
  .adding-elementLeft__add {
    margin-right: 0;
  }
  .adding-element__right {
    margin-left: auto;
    max-width: 572px;
  }
  .adding-element__right .adding-elementRight__clear {
    padding-right: 0;
  }
  .adding-element__right .adding-elementRight__items {
    padding-right: 31px;
    > * > span {
      word-break: break-word;
    }
  }
  .input.input__row > span:first-child {
    margin-right: 5px;
  }
  .input.input__row .input-icon__btn {
    margin-right: 0;
  }
  .addEvent-tab__row {
    display: flex;
    align-items: center;
  }

  .addEvent-tab__row .custom-select.custom-select-row > div:first-child {
    max-width: fit-content;
    margin-right: 15px;
  }

  .input__row > span:first-child {
    max-width: 175px;
    width: 100%;
  }
  .adding-element {
    position: relative;
  }
  .adding-element__left {
    padding-right: 43px;
  }
  .adding-element__right {
    margin-left: initial;
    padding-left: 43px;
  }
  .adding-elementRight__items {
    padding-left: 0;
  }

  .adding-element__left::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    right: 0;
  }
  .adding-element__left::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 15px;
    right: -7px;
    background: url('../../../assets/img/icons/table_icon.svg') no-repeat center
      center;
    background-size: contain;
    top: calc(50% - 7.5px);
  }
  .adding-elementLeft__items::-webkit-scrollbar {
    width: 5px;
  }
  .adding-elementRight__items::-webkit-scrollbar {
    width: 5px;
  }
  .adding-elementLeft__items {
    min-height: 200px;
    position: relative;
  }
  .adding-elementLeft__add .input input {
    width: 100%;
  }
  .addEvent-right__textarea > div {
    width: 100%;
  }
  .addEvent-wrap__left > div:nth-child(4) {
    margin-bottom: 8px;
  }
  .addEvent-booking__tabl-list {
    overflow-x: auto;
    padding-bottom: 10px;
    padding-top: 59px;
    padding-left: 5px;
    .btn {
      height: 45px;
      margin-right: 15px;
      &.error {
        box-shadow: 0 0 6px red;
      }
      > span {
        max-width: 325px;
        overflow: hidden;
      }
    }
  }
  .addEvent-booking__wrapp-btn {
    height: 100%;
    position: relative;
    div {
      white-space: nowrap;
      transition: 0.4s;
    }
    .btn-delete {
      padding-left: 45px;
    }
    div:hover {
      background: #3a36db;
      color: #fff !important;
    }
    .btn-first {
      padding-left: 20px;
    }
    > span {
      position: absolute;
      left: 20px;
      top: calc(50% - 12px);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1420px) {
    .addEvent-wrap {
      flex-direction: column;
    }
    .addEvent-wrap__left {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .addEvent-wrap__right {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1366px) {
    .adding-element__left,
    .adding-element__right {
      padding: 0;
    }
    .adding-element__left::before {
      width: 100%;
      height: 1px;
      bottom: -7px;
      top: initial;
    }
    .adding-element__left::after {
      bottom: -14px;
      right: calc(50% - 7px);
      top: initial;
      transform: rotate(90deg);
    }
    .addEvent-booking__tabs {
      grid-template-columns: 1fr 50px;
    }
    .adding-element > div {
      max-width: initial;
      width: initial;
    }
    .adding-elementLeft__add {
      margin-right: 30px;
    }
    .adding-element__right {
      margin-left: initial;
      margin-right: initial;
      max-width: initial;
    }
    .adding-element {
      flex-direction: column;
    }
    .adding-elementRight__clear {
      margin-left: initial;
      color: #3a36db !important;
      margin-top: 17px;
      margin-bottom: 11px;
    }
    .adding-element__right > * {
      padding-left: 0;
    }
    .adding-element__left {
      margin-right: 20px;
    }
    .addEvent-booking__tabs button {
      padding: 13px 6px 13px 13px;
    }
  }
  @media screen and (max-width: 1200px) {
    .rangeCalendar {
      right: 0;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .projects-naw span {
      font-size: 18px !important;
    }
    .addEvent-wrap__left > div {
      margin-bottom: 31px;
    }
    .custom-select {
      max-width: 100%;
    }
    .addEvent-wrap__left {
      margin-bottom: 31px;
    }
    .addEvent-booking {
      margin-top: 46px;
    }
    .addEvent-right__textarea textarea::placeholder {
      font-size: 14px;
    }
    .addEvent-wrap__right > * > div {
      margin-top: 11px;
    }
    .addEvent-wrap__right > * > span {
      max-width: 204px;
    }
    .addEvent-wrap__right > * {
      margin-bottom: 36px;
    }
    .addEvent-wrap__right {
      padding: 24px 25px 46px;
    }
    .addEvent-booking {
      margin-top: 28px;
    }
    .addEvent-booking__tabs .btn {
      font-size: 14px;
    }
    .addEvent-booking__tabs button {
      padding: 13px 6px 13px 13px;
    }
    .addEvent-booking__tabs {
      margin-bottom: 19px;
    }
    .addEvent-tab__wrap > div:nth-child(1),
    .addEvent-tab__wrap > div:nth-child(2) {
      max-width: 100%;
    }
    .addEvent-tab__wrap > div {
      margin-bottom: 31px;
    }

    .addEvent-tab__row > div:first-child {
      margin-right: 15px;
    }
    .adding-elementLeft__add {
      margin-right: 18px;
    }
    .adding-elementLeft__items > div:first-child {
      margin-top: -10px;
    }
    .adding-elementLeft__items {
      max-height: 259px;
      margin-right: 19px;
    }
    .adding-elementLeft__items::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    .adding-elementLeft__items > * {
      margin-right: 21px;
    }
    .adding-elementRight__items {
      margin-right: 19px;
    }
    .adding-elementRight__items > * {
      margin-right: 3px;
    }
    .adding-elementRight__items::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    .add_event .btn_submit {
      font-size: 14px;
      padding: 14px 31px 13px 31px;
    }
    .content__title {
      margin-bottom: 32px;
    }
    .input.input__row > span:first-child {
      margin-right: 5px;
    }
    .input__row > span:first-child {
      max-width: 175px;
      width: 100%;
    }
  }

  @media screen and (max-width: 1023px) {
    .add_event .h3 {
      font-size: 18px !important;
      margin-bottom: 0px !important;
    }
    .adding-element__right .adding-elementRight__items {
      padding-right: 16px;
    }
    .addEvent-wrap__left {
      box-shadow: none;
      padding: 24px 0px 26px;
      margin-bottom: 7px;
    }
    .addEvent-wrap__left > div {
      margin-bottom: 32px;
    }
    .input.input__row {
      flex-direction: column;
    }
    .addEvent-wrap__left .input input {
      margin-top: 8px;
    }

    .addEvent-wrap__left .select-wrap {
      flex-direction: column;
    }
    .addEvent-wrap__left .select-wrap span {
      margin-bottom: 8px;
    }
    .addEvent-wrap__left .select-wrap .custom-select {
      max-width: 100%;
    }
    .addEvent-wrap__left .addElement {
      margin-top: 15px;
    }
    .addEvent-wrap__right {
      box-shadow: none;
      padding: 0px 0px 26px;
    }
    .addEvent-wrap__right > div {
      flex-direction: column;
    }
    .addEvent-wrap__right > div:first-child {
      margin-bottom: 30px;
    }

    .addEvent-wrap__right > div span {
      margin-bottom: 19px;
    }
    .addEvent-right__checkboxes {
      justify-content: space-between;
    }
    .addEvent-wrap__right .addEvent-right__textarea {
      margin-bottom: 29px;
    }
    .addEvent-right__textarea textarea {
      max-width: 100%;
    }

    .addEvent-right__textarea textarea::placeholder {
      font-size: 14px;
    }
    .custom-select__wrap {
      display: flex !important;
      justify-content: space-between;
      .custom-select {
        border-radius: 10px;
      }
      .ui-select-container {
        border-radius: 10px;
        width: 250px;
      }
      .ui-select__value-container {
        font-size: 14px;
        // background: #3a36db;
      }
    }
    .addEvent-booking {
      margin-top: 58px;
    }
    .custom-select__booking {
      max-width: 180px;
    }
    .custom-select__wrap .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 48px;
      height: 48px;
    }
    .custom-select__wrap .btn svg {
      margin-right: 0;
    }

    .addEvent-booking__tab {
      margin-top: 30px;
    }
    .addEvent-tab__wrap .input__row label {
      margin-bottom: 8px;
    }

    .addEvent-tab__wrap .select__row span {
      margin-bottom: 8px;
    }

    .addEvent-tab__wrap > div {
      margin-bottom: 33px;
      max-width: 100%;
    }
    .addEvent-booking__tab .h4 {
      font-size: 18px !important;
    }
    .addEvent-tab__row {
      flex-direction: column;
    }
    .addingElement-wrap {
      flex-direction: column;
    }

    .adding-elementLeft__add {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding-bottom: 10px;
    }
    .custom-select .select-items {
      width: 100%;
    }
    .adding-element {
      margin-top: 9px;
    }
    .adding-elementLeft__add div:first-child {
      width: fit-content;
    }
    .addingElement-wrap .adding-element__left {
      margin-left: 8px;
    }
    .addingElement-wrap .adding-element__left .input {
      margin-top: 21px;
      height: 37px;
      margin-left: 0;
      margin-right: 17px;
      .inputValid-wrap {
        width: 100%;
      }
    }
    .adding-element__left {
      border-right: none;
    }

    .adding-elementLeft__items {
      max-height: 313px;
      margin-right: 17px;
    }
    .adding-elementLeft__items > * {
      margin-bottom: 6px;
      padding: 11px 4px 16px 0;
      margin-right: 20px;
      padding-right: 15px;
    }
    .adding-element {
      flex-direction: column;
    }
    .adding-elementRight__clear {
      margin-left: initial;
      color: #3a36db !important;
      margin-top: 17px;
      margin-bottom: 11px;
    }
    .adding-element__left > *,
    .adding-element__right > * {
      padding-left: 8px;
    }
    .adding-elementRight__items {
      margin-right: 9px;
    }
    .adding-elementRight__items > * {
      padding-right: 19px;
    }
    .adding-elementRight__items svg {
      width: 21px;
      height: 21px;
    }

    .addEvent-booking__title {
      display: flex !important;
      justify-content: space-between;
      padding-bottom: 9px;
    }
    .adding-element__right .adding-elementRight__items {
      padding-right: 21px;
      width: 100%;
    }
    .adding-element__right {
      margin-right: 9px;
    }

    .adding-elementRight__items > * {
      padding-right: 10px;
      width: 100%;
    }
  }
}

body .addEvent-wrap__step2 {
  @media screen and (max-width: 1366px) {
    .adding-element > div {
      max-width: initial;
      width: initial;
    }
    .adding-element__left {
      margin-right: 0;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .content {
      padding: 40px 50px 26px 50px;
    }
    .addEvent-tab__row label {
      min-width: 175px;
    }
  }
  @media screen and (max-width: 1023px) {
    .content__title {
      margin-bottom: 23px;
    }
    .addEvent-right__textarea span {
      margin-bottom: 5px;
    }
    .addEvent-tab__wrap .input__row {
      margin-bottom: 32px;
      margin-right: 0;
      flex: auto;
      max-width: 100%;
    }
    .addEvent-tab__wrap .custom-select {
      max-width: 100%;
    }
    .addEvent-tab__wrap .select__row {
      flex: auto;
      flex-direction: column;
    }
    .adding-element__left {
      max-width: 100%;
      width: 100%;
      flex: auto;
    }
    .adding-element__right {
      padding-left: 0px;
      margin-left: 8px;
    }
    .custom-select__wrap {
      display: flex !important;
      justify-content: space-between;
    }
    .input.input__row {
      flex-direction: column;
      width: 100%;
    }
    .select__row {
      width: 100%;
    }
    .addEvent-tab__wrap {
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      margin-top: 5px;
    }
  }
}
