.timePicker {
  position: relative;
  .input-icon__btn {
    width: auto !important;
    svg {
      transform: rotate(180deg);
      opacity: 0.5;
    }
  }
  .timePickerPopup {
    background: #fff;
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100px;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    height: 200px;
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      flex: 1;
      .active {
        font-weight: 600;
        color: #3a36db;
      }
    }
  }
}
