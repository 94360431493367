.modal__date {
  padding: 40px;
  min-width: 424px;
  .added-btn {
    margin-bottom: 17px;
    button {
      font-size: 14px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .modal-close {
    z-index: 22;
  }

  @media screen and (max-width: 1023px) {
    padding: 15px 18px 8px 15px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    min-width: 362px;
  }
}
