.rangeCalendar {
  position: absolute;
  right: -150px;
  background: #fff;
  z-index: 21;
  text-align: center;
  .rdrDefinedRangesWrapper {
    display: none;
  }
  &.range__small {
    position: relative;
    right: 0;
    .rdrCalendarWrapper {
      border: none;
      .rdrMonthsVertical .rdrMonth {
        padding: 0;
        max-height: 198px;
        .rdrDay {
          height: 30px;
          .rdrDayNumber {
            padding-top: 5%;
            font-size: 12px;
            line-height: 12px;
          }
          > span {
            bottom: 3px;
            top: 3px;
          }
          .rdrEndEdge,
          .rdrStartEdge {
            left: 4px;
            right: 4px;
          }
          .rdrDayStartPreview {
            left: 4px;
          }
          .rdrDayEndPreview {
            right: 4px;
          }
        }
      }
    }
  }
  .rdrCalendarWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .rdrMonthsVertical {
      .rdrMonth {
        padding: 0 20px 20px;
      }
      .rdrDay {
        height: 44px;
        .rdrDayNumber {
          font-size: 14px;
        }
        > span {
          bottom: 9px;
          top: 9px;
        }
        .rdrEndEdge,
        .rdrStartEdge {
          left: 9px;
          right: 9px;
        }
        .rdrDayStartPreview {
          left: 9px;
        }
        .rdrDayEndPreview {
          right: 9px;
        }
      }
    }
    .rdrWeekDay {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
    }
    .calendar__year {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: 0.5px solid #e0e0e0;
      .ui-select__control {
        min-height: 20px;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        border: none;
        .ui-select__value-container {
          padding-left: 0;
        }
        &::after {
          right: 21px;
          width: 12px;
          height: 10px;
        }
      }
      .custom-select {
        width: 100px;
      }

      .select_year {
        .ui-select__menu {
          > div {
            background-color: #fff;
            text-align: left;
            padding-left: 15px;
            > div {
              border-bottom: none;
              background-color: #fff;
            }
          }
        }
      }
    }
    .rdrMonthsVertical {
      flex: 2;
      order: 1;
      margin-top: 12px;
      margin-bottom: auto;
    }
  }
  .calendar__month {
    overflow: hidden;
    flex: 1;
    order: 2;
    .swiper-initialized {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(#ffffff, transparent, #ffffff);
      }
      &::after {
        content: '';
        position: absolute;
        height: 30px;
        width: 90%;
        top: calc(50% - 15px);
        left: 5%;
        right: 5%;
        z-index: 1;
        pointer-events: none;
        border-top: 1px solid #bebebe;
        border-bottom: 1px solid #bebebe;
      }
    }
    .swiper-wrapper {
      height: 215px;
      .swiper-slide {
        text-align: center;
        transition: all 0.4s;
        font-size: 10px;
        line-height: 16px;

        &.swiper-slide-prev,
        &.swiper-slide-next {
          transform: scale(1.5);
          font-weight: 600;
        }
        &.swiper-slide-active {
          transform: scale(2);
          font-weight: 600;
        }
      }
    }
  }
}
