.modal__booking {
  max-width: 369px;
  width: 100%;
  padding: 80px 80px 90px;
  display: flex;
  flex-direction: column;
  .modal-header {
    margin-bottom: 40px;
  }
  .modal__booking__btn button:last-child {
    width: 100%;
  }
  .modal-booking__checkbox {
    margin-bottom: 40px;
  }
  .modal-booking__checkbox > div {
    margin-top: 15px;
  }
  .modal-booking__checkbox > div:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: 1900px) {
    .modal-booking__checkbox label {
      padding-left: 35px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .modal__booking__btn .btn {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 337px;
    padding: 41px 21px 40px 21px;
    .modal-header {
      font-size: 18px;
    }
    .modal-close {
      top: 19px;
      right: 19px;
    }
    .modal-header {
      margin-bottom: 33px;
    }
    .modal-booking__checkbox label {
      padding-left: 41px;
    }
    .modal__booking__btn {
      margin-top: -4px;
      display: flex;
    }
    .modal__booking__btn button {
      font-size: 14px !important;
    }
    .modal__booking__btn button:first-child {
      margin-right: 30px;
    }
    .modal__booking__btn button:last-child {
      padding: 14px 38px 14px 38px;
    }
    .modal-booking__checkbox .checkbox:not(:last-child) {
      margin-bottom: 22px;
    }
    .modal__booking__btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .modal__booking__btn button:last-child {
      margin-right: 30px;
    }
  }
}
