.modal__users {
  max-width: 584px;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  .wrapper-color .picker-wrapper {
    left: 0;
  }
  .modal-users__items > div {
    margin-bottom: 27px;
  }
  .modal-users__items > div:last-child {
    margin-bottom: 0;
    margin-top: 45px;
  }

  .users-radio > span {
    width: auto;
    max-width: initial;
  }
  .users-radio > div {
    width: 302px;
  }
  .users-color {
    padding-top: 4px;
    margin-bottom: 10px;
    margin-bottom: 26px;
    width: fit-content;
  }
  .users-btn > button {
    &:first-child {
      margin-right: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .modal-header {
      font-size: 22px;
      margin-bottom: 22px;
    }
    .modal__added__items {
      margin-top: 13px;
    }
    .modal__added__items > div {
      margin-bottom: 31px;
    }

    .input label {
      margin-right: 20px;
    }
    .modal-status__wrap {
      margin-top: 43px;
    }
    .users-radio > span {
      max-width: 67px;
      width: 100%;
    }
    .added-btn {
      margin-top: 36px;
    }
    .added-btn button {
      font-size: 14px;
    }
    .users-color {
      margin-bottom: 23px;
    }
    .modal-status__wrap {
      margin-top: 37px;
    }
    .users-btn button {
      padding: 14px 31px 14px 31px;
      font-size: 14px;
    }
    .modal-users__items > div:last-child {
      margin-top: 36px;
    }
    .users-btn {
      display: flex;
    }
    .users-btn button:last-child {
      margin-right: 30px;
    }
    .modalAdd-office .modal-header {
      margin-bottom: 19px;
    }
    .modalAdd-office .users-color {
      margin-bottom: 25px;
    }
    .modalAdd-office .users-btn {
      margin-top: 57px;
    }
    .users-btn {
      display: flex;
      justify-content: flex-start;
    }
    .users-btn button:last-child {
      margin-right: 30px;
    }
    .added-btn {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .added-btn button:last-child {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    padding: 15px 18px 8px 15px;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    .btn {
      font-size: 14px;
    }
    .modal__added__items > div {
      margin-bottom: 32px;
    }
    .modal-header {
      font-size: 16px;
      margin-top: 26px;
    }
    .added-btn {
      margin-top: 29px;
    }
    .added-btn button {
      font-size: 14px;
    }
    .added-btn button:first-child {
      margin-right: 30px;
    }
    .modal-content {
      height: fit-content;
    }
    .modal-status__wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 34px;
      margin-bottom: 10px !important;
    }
    .modal-status__wrap .radio-wrap {
      max-width: 100%;
      flex-direction: column;
    }
    .modal-status__wrap .radio-wrap .h6 {
      font-size: 14px !important;
    }
    .modal-status__wrap .radio-wrap > div {
      padding: 0 0 0 2px;
      margin-top: 17px;
    }

    .modal-status__wrap .radio-wrap > div {
      flex-direction: row;
    }
    .modal-status__wrap .radio-wrap > div div:last-child {
      margin-right: 57px;
    }
    .users-color {
      margin-bottom: 24px;
    }
    .users-btn {
      margin-bottom: 32px !important;
    }
  }
}
