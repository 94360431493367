.page__equipments {
  display: flex;
  align-items: flex-start;
  .custom-select.custom-select-row {
    flex-direction: row;
  }
  .checkbox {
    width: 100%;
    margin-top: 30px;
  }
  .equipment-left {
    flex-grow: 1;
    width: 100%;
  }
  .equipment-filter {
    max-width: 480px;
    margin-bottom: 30px;
  }
  .equipment-new__items > div,
  .equipment-filter > * {
    margin-bottom: 31px;
  }

  .equipment-items {
    padding: 25px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 500px;
    overflow: auto;
  }
  .equipment-items .equipment-items__storage {
    width: 250px;
    padding: 10px 16px;
  }
  .equipment-items__storage > div {
    display: flex;
  }
  .equipment-items__storage > div > div:first-child {
    max-width: 110px;
    width: 100%;
  }
  .equipment-items__storage > div > div:last-child {
    display: flex;
    align-items: center;
  }
  .equipment-items__storage > div > div:last-child > span {
    background: rgba(255, 155, 63, 0.1);
    border-radius: 4px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: #ff9b3f;
  }
  .equipment-items__storage > div > div:last-child > span:first-child {
    margin-right: 0;
    background: transparent;
    color: #000000;
  }

  .equipment-new {
    background-color: #f8f9fb;
    margin-left: 30px;
    flex-grow: 1;
    max-width: 470px;
    width: 100%;
    padding: 40px;
  }
  .equipment-new__title {
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 30px;
  }
  .equipment-new__items > .input > span:first-child,
  .equipment-new__items > .custom-select > div:first-child,
  .equipment-radio > span,
  .input__number > span {
    max-width: 85px;
    width: 100%;
    margin-right: 5px;
  }

  .equipment-new__numbers > div:not(:last-child) {
    margin-bottom: 21px;
  }
  div.equipment-new__numbers {
    margin-bottom: 14px;
  }
  div.equipment-radio {
    margin-bottom: 54px;
  }
  .equipment-new__btn {
    display: flex;
  }
  .equipment-new__btn > button:first-child {
    margin-right: 30px;
  }
  .equipment-new__btn.transfer__btn {
    margin-top: 35px;
  }
  .equipment-new__btn.transfer__btn button:first-child {
    margin-right: 20px;
  }
  .equipment-new__items /* global */.btn__red {
    margin-right: 23px;
  }
  .stripe {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    margin-bottom: 30px;
  }
  .equipment__clear {
    margin-left: auto;
    display: block;
    margin-bottom: 12px;
    margin-top: 37px;
    line-height: 150%;
    color: #b3b3b3;
  }
  div.equipment-add.hide-dekstop {
    display: none !important;
  }
  .input.input__row label {
    font-weight: 700 !important;
  }
  .select-wrap .h6 {
    font-weight: 700 !important;
  }
  .input__number .h6 {
    font-weight: 700 !important;
  }
  .equipment-radio .h6 {
    font-weight: 700 !important;
  }
  .custom-table tr td:first-child {
    font-weight: 600;
  }

  @media screen and (max-width: 1700px) {
    flex-direction: column;
    .equipment-new__items > div,
    .equipment-filter > * {
      margin-bottom: 27px;
    }
    .equipment-filter .select-wrap .select-selected {
      font-size: 12px !important;
      padding: 12px 11px 11px;
    }
    .equipment-add {
      display: flex !important;
      margin-left: initial;
      align-items: center;
      justify-content: initial;
      margin-top: 13px;
    }
    .equipment-add svg {
      width: 23px;
      height: 23px;
      margin-right: 5px;
      margin-left: 0;
    }
    .equipment-add .h6 {
      font-size: 14px !important;
    }
    .equipment-items {
      padding: 7px 7px 20px 7px;
      overflow-x: scroll;
      max-width: calc(100vw - 40px);
      margin-top: 15px;
    }
    .custom-table th {
      font-size: 12px;
      padding: 16px 16px 17px 23px;
    }
    .custom-table td {
      font-size: 12px;
      &:nth-child(1) {
        max-width: 130px;
        width: 100%;
      }
    }
    div.mobile-icon_equipment {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      margin-left: -15px;
      margin-top: 20px;
    }
    .mobile-icon_equipment svg {
      width: 15px;
      height: 11px;
      margin-left: 5px;
    }
    .custom-table tr td:nth-child(5),
    .custom-table tr td:nth-child(3) {
      font-weight: 600;
    }
    .equipment-new {
      display: none;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .equipment-new__items > div,
    .equipment-filter > * {
      margin-bottom: 32px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    padding: 40px 50px 26px 50px;
    .projects-naw span {
      font-size: 18px !important;
    }
    .content__title {
      margin-bottom: 33px;
    }
    .equipment-filter .input label {
      font-size: 14px;
    }
    div.equipment-filter .select-wrap .h6 {
      font-size: 14px !important;
    }
    .equipment-filter .input input::placeholder {
      font-size: 14px;
    }
    .equipment-filter > * {
      margin-bottom: 29px;
    }
    .equipment-filter > .addElement {
      margin-bottom: 0;
    }
    div.equipment-filter .select-wrap .h6 {
      margin-right: 17%;
    }
    .equipment-filter .select-wrap .select-selected {
      font-size: 14px !important;
    }
    table.custom-table {
      overflow: initial;
    }
    div.mobile-icon_equipment {
      width: 29px;
      height: 29px;
      margin: 0;
      position: absolute;
      transform: translate(-50%, -50%);
      left: -1px;
      top: 50%;
    }
    .equipment-add {
      display: flex !important;
    }
    .select-column > span {
      font-weight: 700 !important;
    }
  }

  @media only screen and (max-width: 1023px) {
    div.content__title {
      margin-bottom: 23px;
    }
    .checkbox {
      margin-top: 43px;
    }
    .equipment-add span {
      margin-right: 4px;
    }
    .select-column > span {
      font-weight: 700 !important;
    }
  }
  @media only screen and (max-width: 576px) {
    .equipment-filter {
      .input > label:first-child,
      .custom-select > div:first-child {
        max-width: 85px;
        width: 100%;
        margin-right: 5px;
      }
    }
  }
}
