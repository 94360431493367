.wrapper-color {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  .picker-wrapper {
    position: absolute;
    z-index: 5;
    left: -70px;
  }
}
