.rangeCalendar {
  .rdrDateRangePickerWrapper {
    width: 344px;
  }
  .rdrCalendarWrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #000;
    background: transparent;
  }
  .rdrMonth {
    width: 100%;
  }
  .rdrMonthAndYearWrapper {
    padding-top: 27px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    width: 88%;
    margin: 0 auto;
    height: auto;
  }
  .rdrMonthAndYearWrapper {
    .rdrPprevButton {
      margin-left: -10px;
    }
    .rdrNextPrevButton {
      background-color: transparent;
    }
    .rdrNextButton {
      margin-right: -10px;
    }
  }
  .rdrMonthsVertical {
    padding-top: 13px;
    width: 94%;
    margin: 0 auto;
  }
  .rdrDays {
    padding-top: 4px;
    .rdrDayNumber {
      padding-top: 10%;
      font-size: 14px;
      span:after {
        bottom: -6px;
        background: transparent;
      }
    }
    .rdrDay {
      height: 44px;
      > span {
        bottom: 9px;
        top: 9px;
      }
      .rdrEndEdge,
      .rdrStartEdge {
        left: 9px;
        right: 9px;
        background-color: #3b32e0;
        border-radius: 50%;
      }
      .rdrEndEdge:after,
      .rdrStartEdge::after {
        content: '';
        position: absolute;
        background: rgb(229, 223, 255);
        top: 0;
        width: calc(50% + 9px);
        bottom: 0;
        z-index: -1;
      }
      .rdrStartEdge:after {
        right: -9px;
      }
      .rdrEndEdge:after {
        left: -9px;
      }
      .rdrStartEdge.rdrEndEdge:after {
        display: none;
      }
      .rdrDayStartPreview {
        left: 9px;
      }
      .rdrDayEndPreview {
        right: 9px;
      }
    }
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #000;
  }
  .rdrDayPassive {
    pointer-events: initial;
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrMonthAndYearPickers {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }
}
// rdrDay rdrDayStartOfWeek rdrDayHovered rdrDayActive
.rangeCalendar__monthDisabled .rdrDays .rdrDay {
  border: none !important;
  pointer-events: none;
  .rdrSelected {
    background: transparent;
  }
  span.rdrDayStartPreview.rdrDayEndPreview {
    display: none !important;
  }
  span.rdrDayNumber::after,
  .rdrDayStartOfWeek {
    border: none !important;
  }
  &.rdrDayToday .rdrDayNumber {
    background-color: #3b32e0;
    border-radius: 50%;
    left: 4px;
    right: 4px;
    span {
      color: #fff;
    }
  }
}
.rangeCalendar__oneDay .rdrDays .rdrDay {
  .rdrSelected {
    background-color: #3b32e0;
    border-radius: 35%;
    left: 6px;
    right: 6px;
    & ~ span.rdrDayNumber > span {
      color: #fff;
    }
  }
  &.rdrDayPassive {
    display: block;
  }
  span.rdrDayNumber::after {
    left: 4px;
    right: 4px;
    border-radius: 35%;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  span.rdrDayStartPreview.rdrDayEndPreview {
    display: none;
  }
  .rdrDayStartOfWeek {
    border: none !important;
  }
}
