.wall-wrap {
  .wall-chat__month {
    font-size: 12px;
    text-transform: capitalize;
    color: #868686;
  }
  .wall-wrap__tabs {
    max-width: 330px;
    margin-bottom: 31px;
  }
  .wall-chat {
    max-width: 563px;
    width: 100%;
  }
  .wall-chat__items {
    padding-right: 18px;
    width: 100%;
    max-height: 550px;
    min-height: 200px;
    height: 100%;
    overflow-y: auto;
  }
  .wall-chat__shadow {
    display: flex;
    pointer-events: none;
    max-width: 560px;
    width: 100%;
    height: 50px;
    background: linear-gradient(transparent, white);
    margin-top: -50px;
    z-index: 10;
    position: relative;
  }
  .wall-chat__texarea {
    margin-bottom: 3px;
  }
  .wall-chat__item {
    background: #f8f9fb;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 29px;
  }
  .wall-chatItem__text {
    background: #ffffff;
    border-radius: 4px;
    margin-top: 8px;
    padding: 15px;
    display: flex;
    border: 1px solid transparent;
  }
  .wall-chatItem__text > span {
    display: flex;
    padding: 2px;
    margin-left: 15px;
    cursor: pointer;
    svg {
      pointer-events: none;
    }
  }
  .wall-chatItem__text > div {
    width: 80%;
    margin-right: auto;
    display: flex;
    word-break: break-word;
  }
  .wall-chatItem__active {
    border: 1px solid #e0e0e0;
  }
  .wall-chatItem_texarea {
    textarea {
      padding: 0;
      border: none;
      width: 100%;
      font-size: 14px;
      line-height: 150%;
    }
  }
  .wall-chat__new {
    margin-top: 30px;
    padding: 20px 20px 18px 19px;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    max-width: 540px;
  }
  .wall-chat__new textarea {
    border: none;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
    padding: 15px 0px 24px;
    color: #000;
    font-size: 14px;
    line-height: 150%;
    outline: none;
    margin-bottom: 3px;
  }
  .wall-chatNew__btn,
  .wall-chat__new > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wall-chatNew__btn svg {
    margin-right: 11px;
    margin-top: -1px;
  }
  .wall-wrap__tabs button {
    margin-right: 3%;
  }
  .wall-wrap__tabs button:first-child {
    margin-right: 23%;
  }

  .wall-wrap__tabs {
    width: 100%;
    max-width: 57.7%;
  }
  .wall-wrap__tabs .select-wrap {
    margin-left: auto;
    max-width: 243px;
    width: 100%;
    margin-top: 9px;
    .ui-select__single-value {
      font-size: 12px;
      color: #b3b3b3;
    }
  }
  .tabs {
    border-bottom: none;
    position: relative;
  }
  .tabs::before {
    content: '';
    width: 50%;
    height: 1px;
    background: #e0e0e0;
    position: absolute;
    bottom: -1px;
  }
  .wall-chat__item > div:first-child {
    font-weight: 700 !important;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .wall-wrap__tabs {
      max-width: 83.7%;
    }
    .tabs::before {
      width: 65%;
    }
    .wall-chat__month {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 40px 50px 26px 50px;
    .content__title {
      margin-bottom: 34px;
    }
    .projects-naw span {
      font-size: 18px !important;
    }
    .tabs {
      justify-content: initial;
    }
    .wall-wrap__tabs button:first-child {
      margin-right: 60px;
    }
    .tabs button {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .wall-wrap__tabs {
      width: 100%;
      max-width: 100%;
    }
    .wall-wrap__tabs .select-wrap {
      margin-left: auto;
      max-width: 243px;
      width: 100%;
    }
    .select-selected {
      font-size: 12px;
    }
    .wall-chat__month {
      font-size: 12px;
      color: #868686;
      margin-bottom: 10px;
    }
    .wall-chat {
      max-width: 100%;
    }
    .wall-chat__items {
      padding-right: 0;
    }
    .wall-chat__item {
      margin-bottom: 20px;
    }
    .wall-chat__new {
      max-width: 100%;
    }
    .tabs {
      border-bottom: none;
      position: relative;
    }
    .tabs::before {
      content: '';
      width: 40%;
      height: 1px;
      background: #e0e0e0;
      position: absolute;
      bottom: -1px;
    }
    .select-selected {
      padding: 11px;
    }
  }

  @media screen and (max-width: 1023px) {
    padding-bottom: 0;
    .content__title {
      margin-bottom: 23px;
    }
    .wall-chat {
      max-width: 100%;
    }
    .wall-wrap__tabs {
      justify-content: initial;
    }
    .wall-wrap__tabs button {
      font-size: 18px;
      padding-bottom: 7px;
    }
    .wall-wrap__tabs button:first-child {
      margin-right: 60px;
    }
    .select-wrap {
      margin-bottom: 20px;
    }
    .wall-chat__month {
      font-weight: 500;
      font-size: 12px;
      color: #868686;
      display: block;
      margin-top: 8px;
    }
    .select-wrap .custom-select {
      max-width: 100%;
    }
    .select-wrap .select-selected {
      font-size: 12px;
    }
    .wall-chat__items .wall-chat__item:nth-child(2) {
      margin-top: 10px;
    }
    .wall-chat__items {
      padding-right: 0;
      max-height: 503px;
    }
    .wall-chat__items .wall-chat__item {
      padding: 15px 15px 15px 15px;
      margin-bottom: 21px;
    }
    .wall-chat__item div:first-child {
      margin-bottom: 10px !important;
    }
    .wall-chatItem__text {
      position: relative;
      padding: 10px 11px 9px 11px;
    }
    .wall-chat__item .wall-chatItem__text > div {
      width: 100%;
      margin-bottom: 0 !important;
    }
    .wall-chatItem__text span {
      position: absolute;
    }
    .wall-chatItem__text span:nth-child(2) {
      top: -27px;
      right: 30px;
    }
    .wall-chatItem__text span:last-child {
      top: -27px;
      right: 0px;
    }
    .wall-chat__new {
      background: #f1f4fa;
      border: none;
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
      max-width: initial;
      border-radius: 8px 8px 0px 0px;
      padding-bottom: 43px;
    }
    .wall-chat__new textarea {
      background: #f1f4fa;
      padding: 15px 4px 0px;
      height: 51px;
    }
    .wall-chat__new textarea::placeholder {
      font-size: 14px;
    }
    .checkbox label {
      font-size: 12px;
      margin-left: -4px;
      margin-top: 5px;
    }
    .wall-chatNew__btn {
      margin-top: 5px;
    }
    .select-items {
      width: 100%;
    }
    .wall-wrap__tabs {
      max-width: 100%;
      margin-bottom: -2px;
      flex-wrap: wrap;
    }
    .wall-wrap__tabs .select-wrap {
      max-width: 100%;
      margin-top: 29px;
    }
    .select-wrap {
      max-width: 100%;
    }
    .tabs::before {
      bottom: 93px;
      width: 100%;
    }
  }
}
