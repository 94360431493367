.modal__categories {
  .modal_added_set.show {
    display: block;
  }
  .modal_added_category.show {
    display: block;
  }
  .modal_added_warehouse.show {
    display: block;
  }
  .categories__clear {
    margin-left: auto;
    display: block;
    margin-bottom: 10px;
  }
  .modal_added__items > div {
    > span,
    > label {
      max-width: 85px;
      width: 100%;
    }
  }
  .categories-new__btn {
    > button {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    max-width: 584px;
    padding: 76px 40px 40px 40px;
    width: 100%;
    .modal-close {
      top: 42px;
      right: 40px;
    }
    .modal_transfer__clear {
      display: block;
      text-align: right;
      font-size: 14px;
      color: #b3b3b3;
    }
    .modal-header {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .modal_added__items {
      margin-top: 13px;
    }
    .modal_added__items > div {
      margin-bottom: 31px;
    }
    .input label {
      margin-right: 20px;
    }
    .added-btn {
      margin-top: 0px;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
    .added-btn button {
      font-size: 14px;
      height: 49px;
      display: flex;
    }
    .added-btn button:last-child {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    padding: 15px 15px 6px 15px;
    width: 100%;
    .modal-close {
      top: 18px;
      right: 18px;
    }
    .modal-header {
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 31px;
    }
    .modal_added__items > div {
      margin-bottom: 34px;
    }

    .added-btn {
      margin-top: 23px;
      align-items: center;
      display: flex;
    }
    .added-btn svg {
      width: 20px;
      height: 20px;
    }
    .added-btn button {
      font-size: 14px !important;
      align-items: center;
      display: flex;
    }
    .added-btn button:first-child {
      margin-right: 16px;
    }
    .modal-content {
      height: fit-content;
    }
  }
}
