$delay: 0.5s;
$animationDelay: 2s;

button > .small-loader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small-loader {
  position: absolute;
  color: transparent;
  min-width: 21px;
  min-height: 21px;
  &__wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__container {
    display: inline-block;
    animation: MuiCircularProgress-keyframes-circular-rotate $animationDelay
      linear infinite;
  }
  &__svg {
    display: block;
    animation: loading_animation $animationDelay infinite;
  }
  &__circle {
    stroke: currentColor;
    animation: MuiCircularProgress-keyframes-circular-dash $animationDelay
      ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }
  &__color {
    &--primary {
      color: #ffffff;
    }
    &--secondary {
      color: #4a66c9;
    }
  }
}

@keyframes MuiCircularProgress-keyframes-circular-rotate {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes MuiCircularProgress-keyframes-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
