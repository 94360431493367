.fullCalendar__popup {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 363px;
  width: 100%;
  min-height: 429px;
  padding: 49px 25px 25px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transition: all 0.4s;
}
.fullCalendar-popup__title {
  background: linear-gradient(
      0deg,
      rgba(0, 133, 255, 0.1),
      rgba(0, 133, 255, 0.1)
    ),
    #ffffff;
  margin: 10px 0 30px;
  border-radius: 4px;
  padding: 5px 10px;
}
.fullCalendar-popupItem__title {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
}
.fullCalendar-popupItem__title > span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: -2px;
}
.fullCalendar-popupItem__title > div {
  max-width: calc(100% - 20px);
  overflow: hidden;
  word-break: break-all;
}
.fullCalendar-popupItem__dataStar,
.fullCalendar-popupItem__dataEnd {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding-bottom: 7px;
  border-bottom: 1px solid #e5e7eb;
}
.fullCalendar-popup__items {
  flex-grow: 1;
  display: grid;
  max-height: 251px;
  overflow-y: auto;
  padding-right: 8px;
}
