@media screen and (max-width: 1366px) {
  .h2 {
    font-size: 22px !important;
  }
  .h4 {
    font-size: 14px !important;
    line-height: 144%;
  }
  .show-tabled {
    display: block !important;
  }
  .hide-tabled {
    display: none !important;
  }
  .hide-tablet {
    display: none;
  }

  .btn {
    padding: 14px 29px 14px 28px;
    font-size: 12px;
  }

  /* Menu */
  .menu {
    min-width: 286px;
    padding: 27px 0 41px 40px;
  }
  .menu .logo {
    margin-bottom: 41px;
  }
  .menu .btn__menu {
    right: -15px;
    top: 39px;
  }
  .menu .logo img:first-child {
    width: 130px;
  }
  .addEvent {
    width: auto;
  }
  .menu-items {
    margin-top: 43px;
  }
  .menu-items a {
    font-size: 14px;
    height: 44px;
  }
  .menu-items li.active::before {
    width: 4px;
    height: 92%;
  }
  .menu-items .icons__stock {
    width: 16px;
    height: 16px;
  }
  .menu-items .icons__active {
    width: 16px;
    height: 16px;
  }
  .menu-user {
    margin-top: 43px;
    margin-left: 0;
  }
  .menu-user img {
    width: 40px;
  }
  .menu-user span {
    font-size: 14px;
  }

  /* content */
  .content {
    padding: 50px 40px;
  }
  .profile-naw .h4 {
    font-size: 18px !important;
  }
  .profile-naw .btn {
    font-size: 14px;
    padding: 12px 29px 11px 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .menu .addEvent {
    padding: 14px 27px 14px 27px;
    font-size: 14px;
    margin-left: -10px;
  }
  .menu-items a {
    font-size: 15px;
  }
  .menu-items__actions {
    right: 27px;
    top: 45%;
    width: 30px;
    height: 23px;
    font-size: 11px;
  }
  .btn {
    padding: 14px 31px 14px 30px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .content__title {
    font-size: 16px;
  }
  .menu .addEvent {
    padding: 14px 26px 14px 26px;
    font-size: 14px;
    margin-left: -9px;
  }
  .menu-items a {
    font-size: 15px;
  }
  .menu-items__actions {
    right: 27px;
    top: 45%;
    width: 30px;
    height: 24px;
    padding: 2px 8px 0px 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .content {
    padding: 40px 40px 26px 40px;
  }
}

@media screen and (max-width: 1023px) {
  .h2 {
    font-size: 16px !important;
  }
  .h4 {
    font-size: 13px !important;
  }
  .show-mobile {
    display: block !important;
  }
  .show-mobile-flex {
    display: flex !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .show-table-mobile {
    display: table-cell !important;
  }

  /* content */
  main {
    flex-direction: column;
  }
  .content {
    padding: 26px 20px;
  }
  .content__title {
    font-size: 12px;
    margin-bottom: 27px;
  }

  /* header */
  .content .btn_eddit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    margin-top: -5px;
    width: 34px;
    height: 34px;
  }
  .btn_eddit img {
    margin-top: 0;
    margin-right: 0;
    height: 12px;
    width: 12px;
  }
  .btn_eddit svg {
    height: 12px;
    width: 14px;
  }
  .projects-naw__title {
    display: flex;
    font-size: 12px !important;
  }
  .projects-naw__title svg {
    margin-right: 17px;
  }

  /* Menu */
  .menu {
    position: absolute;
    min-width: 300px;
    right: -500px;
    z-index: 200;
    background: #f1f4fa;
    border-radius: 20px 0px 0px 20px;
    min-height: 100vh;
    height: 100%;
    padding: 27px 0 41px 30px;
  }
  .menu .logo {
    display: none;
  }
  .menu .btn__menu {
    display: none;
  }
  .menu .addEvent {
    margin-top: 44px;
  }

  .header-mobile {
    display: block;
    background-color: #f1f4fa;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 22px 20px 46px 20px;
  }

  .header-mobile .logo-mobile img {
    width: 109px;
  }

  .menu-items {
    margin-top: 20px;
  }

  .menu-items li a {
    height: 54px;
  }
  .menu-user {
    margin-top: 21px;
  }

  .menu.open {
    right: 0;
    -webkit-box-shadow: 0 0 59px 760px rgba(0, 0, 0, 40%);
    -moz-box-shadow: 0 0 59px 760px rgba(0, 0, 0, 40%);
    box-shadow: 0 0 59px 760px rgba(0, 0, 0, 40%);
  }

  .mobile-burger {
    width: 23px;
    margin-right: 3px;
    cursor: pointer;
    position: relative;
    z-index: 201;
  }
  .mobile-burger span {
    display: block;
    background: #000;
    border-radius: 10px;
    height: 2px;
    margin: 4px 0;
    border-radius: 1px;
    width: 100%;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .mobile-burger.active span:nth-of-type(1) {
    transform: rotate(135deg);
    margin-top: 11px;
    width: 24px;
  }
  .mobile-burger.active span:nth-of-type(2) {
    display: none;
  }
  .mobile-burger.active span:nth-of-type(3) {
    transform: rotate(45deg);
    margin-top: -6px;
    width: 24px;
  }

  /* select  */

  .custom-select.custom-select-row {
    flex-direction: column;
  }

  /*  content */
  .content {
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
  }

  /*  language */

  .language {
    top: initial;
    right: 10px;
    bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /*  language */

  .language-popap {
    padding: 0 25px;

    > img {
      max-width: 80px;
      max-height: 80px;
    }

    > img:not(:last-child) {
      margin-right: 20px;
    }
  }
}
