@import '../../../assets/scss/mixins';

.loader {
  &__wrap {
    flex-grow: 1;
    height: 100%;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .loader {
    &__wrap {
      position: initial;
    }
  }
}
